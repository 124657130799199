<template>
<div class="container rgpd">
    <h1 class="rgpd__title">Politique de protection des données personnelles</h1>
    <h3 class="rgpd__subtitle">1. Responsable du traitement</h3>
    <p>
      Le responsable du traitement des données collectées via l'application OBY est <b>Bien Vieillir IDF</b>, représentée par son administrateur. Pour toute question ou réclamation relative au traitement des données, vous pouvez contacter notre Délégué à la Protection des Données (DPO) à l'adresse mail suivante : <b>dpo@bienvieillir-idf.org</b>.
    </p>
    <h3 class="rgpd__subtitle">2. Nature des données collectées</h3>
  <h4 class="rgpd__subtitle__h4">2.1 Utilisateurs</h4>
    <p>
      Les données collectées sur les utilisateurs incluent :
    </p>
  <p>
    <ul>
      <li><b>•	Informations administratives</b> : nom, prénom, état civil, profession, adresse postale, numéro de téléphone, adresse e-mail.</li>
      <li><b>•	Données de connexion</b> : adresse IP, journaux de connexion, identifiants d’accès.</li>
    </ul>
  </p>
  <h4 class="rgpd__subtitle__h4">2.2 Patients</h4>
  <p>
    Les données collectées sur les patients incluent :
  </p>
  <ul>
    <li><b>•	Informations administratives</b> : nom, prénom, état civil, coordonnées (adresse postale, numéro de téléphone, adresse e-mail).</li>
    <li><b>•	Données de santé</b> : informations médicales, grille AGGIR, état de santé général, traitements médicaux.</li>
    <li><b>•	Documents personnels</b> : carte vitale, carte nationale d’identité (CNI), RIB, photo.</li>
  </ul>
  <h4 class="rgpd__subtitle__h4">2.3 Entourage du patient</h4>
  <p>
    Les données collectées sur l'entourage du patient incluent :
  </p>
  <ul>
    <li><b>•	Informations administratives</b> : nom, prénom, état civil, coordonnées (adresse postale, numéro de téléphone, adresse e-mail).</li>
  </ul>
  <h3 class="rgpd__subtitle">3. Finalité du traitement</h3>
  <p>
    Les données sont collectées pour les finalités suivantes :
  </p>
  <ul>
    <li><b>•	Évaluation des besoins des patients</b> dans le cadre de leur prise en charge.</li>
    <li><b>•	Suivi administratif et médical</b> des patients.</li>
    <li><b>•	Partage sécurisé</b> des comptes rendus et des documents (PDF) avec l'entourage des patients ou les professionnels de santé désignés.</li>
  </ul>
  <p>
    Finalités complémentaires :
  </p>
  <ul>
    <li><b>•	Amélioration des services</b> : analyse des données collectées sous forme anonymisée ou pseudonymisée afin de renforcer la qualité des services.</li>
    <li><b>•	Respect des obligations légales</b> : garantir la conformité aux exigences légales et réglementaires liées à la gestion des soins et des dossiers médicaux.</li>
  </ul>
  <h3 class="rgpd__subtitle">4. Durée de conservation des données</h3>
  <h4 class="rgpd__subtitle__h4">4.1 Données des patients</h4>
  <p>
    Les données des patients sont conservées pendant une durée de <b>20 ans</b> après la fin de leur prise en charge médicale, conformément à la réglementation en vigueur en France sur la gestion des dossiers médicaux. Cette durée peut être prolongée en cas de litige ou si la loi impose une conservation plus longue.
  </p>
  <h4 class="rgpd__subtitle__h4">4.2 Données des utilisateurs (professionnels de santé)</h4>
  <p>
    Les données des utilisateurs sont conservées pendant une durée de <b>5 ans</b> après la fin de leur dernière utilisation de l’application, sauf demande de suppression anticipée. Cette durée peut être prolongée en cas de litige ou d'obligation légale.
  </p>
  <h4 class="rgpd__subtitle__h4">4.3 Critères de détermination de la durée de conservation :</h4>
  <ul>
    <li><b>•	Données anonymisées</b> : Les données anonymisées à des fins statistiques ou de recherche peuvent être conservées sans limite de durée, sous réserve de garanties adéquates pour la protection des utilisateurs.</li>
    <li><b>•	Cas de litige</b> : Si un litige survient, les données peuvent être conservées aussi longtemps que nécessaire pour protéger les droits de Bien Vieillir IDF.</li>
  </ul>
  <h3 class="rgpd__subtitle">5. Droits des utilisateurs</h3>
  <p>
    Conformément au RGPD, les utilisateurs disposent des droits suivants :
  </p>
  <ul>
    <li><b>•	Droit d'accès</b> : obtenir une copie des données personnelles vous concernant.</li>
    <li><b>•	Droit de rectification</b> : corriger les données inexactes ou incomplètes.</li>
    <li><b>•	Droit de suppression</b> : demander la suppression des données sous certaines conditions.</li>
    <li><b>•	Droit de portabilité</b> : recevoir vos données dans un format structuré et couramment utilisé, et les transmettre à un autre responsable du traitement.</li>
    <li><b>•	Droit d’opposition</b> : s’opposer au traitement de vos données dans certaines circonstances.</li>
    <li><b>•	Droit de limitation du traitement</b> : demander la suspension du traitement des données en cas de litige ou de vérification de l’exactitude des données.</li>
    <li><b>•	Droit de retirer votre consentement</b> : à tout moment, sans que cela ne porte atteinte à la légalité du traitement fondé sur le consentement avant ce retrait.</li>
    <li><b>•	Droit d’introduire une réclamation auprès de la CNIL</b> : en cas de litige non résolu.</li>
  </ul>
  <p>
    Pour exercer ces droits, veuillez contacter notre DPO à <b>dpo@bienvieillir-idf.org</b>.
  </p>
  <h3 class="rgpd__subtitle">6. Sécurité des données</h3>
  <p>
    Bien Vieillir IDF met en œuvre des <b>mesures techniques et organisationnelles</b> strictes pour protéger vos données :
  </p>
  <ul>
    <li><b>•	Chiffrement</b> des données sensibles (CNI, carte vitale, RIB, etc.).</li>
    <li><b>•	Contrôle d'accès</b> : seules les personnes autorisées peuvent accéder aux données.</li>
    <li><b>•  Audits de sécurité</b> : des audits et tests de vulnérabilité sont régulièrement réalisés.</li>
    <li><b>•	Hébergement sécurisé</b> : les données sont hébergées par <b>PROGINOV</b>, un hébergeur certifié <b>HDS</b> (Hébergeur de Données de Santé), garantissant un niveau de protection conforme aux exigences légales.</li>
  </ul>
  <h4 class="rgpd__subtitle__h4">6.1 Gestion des violations de données</h4>
  <p>
    En cas de violation des données, Bien Vieillir IDF s’engage à :
  </p>
  <ul>
    <li><b>•</b>  Notifier la <b>CNIL</b> dans les <b>72 heures</b> suivant la détection de la violation.</li>
    <li><b>•</b>  Informer les utilisateurs concernés, si la violation présente un risque élevé pour leurs droits et libertés, et proposer des mesures correctives immédiates (changement de mots de passe, surveillance renforcée, etc.).</li>
  </ul>
  <h3 class="rgpd__subtitle">7. Partage des données</h3>
  <p>
    Les données des patients peuvent être partagées avec l'entourage ou les professionnels de santé <b>désignés</b> par les patients eux-mêmes, sous réserve de leur <b>consentement explicite</b>.
  </p>
  <h4 class="rgpd__subtitle__h4">7.1 Sous-traitants et tiers</h4>
  <p>
    Les données peuvent être partagées avec des sous-traitants impliqués dans la gestion de l’application (hébergeur, prestataires techniques), sous réserve qu’ils respectent les mêmes engagements de sécurité et de confidentialité, conformément au RGPD.
  </p>
  <h4 class="rgpd__subtitle__h4">7.2 Transferts internationaux</h4>
  <p>
    Aucun transfert de données hors de l’Union européenne n’est effectué. Si cela devait changer, Bien Vieillir IDF s’engage à mettre en place des garanties appropriées conformément aux exigences du RGPD.
  </p>
  <h3 class="rgpd__subtitle">8. Accès via Pro Santé Connect</h3>
  <p>
    L'application Bien Vieillir IDF permet aux professionnels de santé de se connecter via <b>Pro Santé Connect</b>, un service sécurisé d'authentification électronique mis en œuvre par l'Agence du Numérique en Santé (ANS). Ce service repose sur l'utilisation de la <b>carte de professionnel de santé (CPS)</b>, et traite des données personnelles (nom, prénom, numéro CPS) strictement nécessaires à l’authentification.
  </p>
  <p>
    Ces données sont traitées uniquement à des fins d’authentification et de sécurisation des connexions. Bien Vieillir IDF et l'ANS sont coresponsables du traitement de ces données, conformément au RGPD. Pour plus d’informations, veuillez consulter les <b>Conditions Générales d’Utilisation de Pro Santé Connect</b>.
  </p>
  <h3 class="rgpd__subtitle">9. Cookies</h3>
  <p>
    Les cookies utilisés par l’application sont strictement limités à la gestion des sessions utilisateurs et à l’authentification via Pro Santé Connect. Aucun cookie à des fins publicitaires ou de profilage n’est utilisé. Les utilisateurs peuvent configurer leur navigateur pour bloquer ou supprimer les cookies, bien que cela puisse affecter certaines fonctionnalités de l’application.
  </p>
  <h3 class="rgpd__subtitle">10. Modification de la politique</h3>
  <p>
    Cette politique de protection des données peut être modifiée à tout moment en fonction des évolutions législatives ou des services proposés. Les utilisateurs seront informés des modifications via l’application.
  </p>
</div>
</template>
<script>
export default {
    name: "Rgpd"
}
</script>
<style scoped>
.rgpd__title {
    font: normal 20px/17px "ProximaNova", sans-serif;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
}

.rgpd__subtitle {
    font: 700 16px/28px "ProximaNova", sans-serif;
}
.rgpd__subtitle__h4 {
  font: 500 15px/28px "ProximaNova", sans-serif;
}
.rgpd  {
    font: normal 14px/28px "ProximaNova", sans-serif;
    margin-top: 2rem;
}
</style>