<template>
    <div class="container mention">
        <h1 class="mention__title">Mentions Légales</h1>
        <h3 class="mention__subtitle">1. Identification de l'éditeur du site et de l'application</h3>
        <ul>
          <li><b>•	Nom de l'entreprise</b>&nbsp;: Bien Vieillir IDF</li>
          <li><b>•	Forme juridique</b>&nbsp;: Groupement de coopération social et médico-social</li>
          <li><b>•	Siège social</b>&nbsp;: 16 Bis rue Louis Dupré – 94100 Saint Maur des Fossés</li>
          <li><b>•	N° SIRET</b>&nbsp;: 889 755 658 00016</li>
          <li><b>•	Numéro de TVA intracommunautaire</b>&nbsp;: FR34889755658</li>
          <li><b>•	Directeur de la publication</b>&nbsp;: Didier PAGEL</li>
        </ul>
        <h3 class="mention__subtitle">2. Hébergement de l'application</h3>
      <ul>
        <li><b>•	Nom de l'hébergeur</b>&nbsp;: PROGINOV</li>
        <li><b>•	Adresse</b>&nbsp;: 36, rue de la Guillauderie - 44118 La Chevrolière</li>
        <li><b>•	Téléphone</b>&nbsp;: +33(0)2 51 70 93 93</li>
        <li><b>•	Site web</b>&nbsp;:&nbsp;<a href="https://www.proginov.com" style="color: #1e48d1">www.proginov.com</a></li>
      </ul>
      <h3 class="mention__subtitle">3. Propriété intellectuelle</h3>
        <p>
          Le contenu de l'application (textes, images, graphiques, logos, vidéos, sons, logiciels) est la propriété exclusive de Bien Vieillir IDF ou de ses partenaires et est protégé par le Code de la Propriété Intellectuelle, notamment l’article <b>L.122-4</b>.
        </p>
      <p>
        Toute reproduction, représentation, modification, publication, adaptation, en tout ou partie, de ces éléments, sans l'autorisation expresse et préalable de l'entreprise est interdite et constitutive d'une contrefaçon sanctionnée par le Code de la Propriété Intellectuelle.
      </p>
        <h3 class="mention__subtitle">4. Données personnelles</h3>
        <p>
          Le traitement des données personnelles collectées via l'application est conforme aux exigences du <b>Règlement Général sur la Protection des Données (RGPD)</b> et de la <b>Loi Informatique et Libertés</b>. Pour en savoir plus, veuillez consulter notre <a href="/#/rgpd" style="text-decoration: underline ;color: #1e48d1">Politique de Protection des Données</a>.
        </p>
        <h3 class="mention__subtitle">5. Cookies</h3>
        <p>
          L'utilisation des cookies est strictement limitée à la gestion des sessions utilisateurs et au téléchargement des fichiers PDF et à la connexion à l'application web pour authentifier les utilisateurs et maintenir leur session active.
        </p>
        <h3 class="mention__subtitle">6. Limitation de responsabilité</h3>
        <p>
          Bien Vieillir IDF ne peut être tenue pour responsable de tout dommage direct ou indirect découlant de l'utilisation de l'application, y compris la perte de données ou tout autre dommage informatique.
        </p>
        <h3 class="mention__subtitle">7. Contact</h3>
        <p>
          Pour toute question, vous pouvez nous contacter par e-mail : <span style="text-decoration: underline ;color: #1e48d1">contact@bienvieillir-idf.org</span> ou par courrier à l'adresse de notre siège social.
        </p>
    </div>
</template>
<script>
export default {
    name: "Mention"
}
</script>
<style scoped>

li {
  display: flex;
}

.mention__title {
    font: normal 20px/17px "ProximaNova", sans-serif;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
}

.mention__subtitle {
    font: 700 16px/28px "ProximaNova", sans-serif;
}
.mention  {
    font: normal 14px/28px "ProximaNova", sans-serif;
    margin-top: 2rem;
}
</style>