<template>
  <div class="bo--agence row mx-0 justify-content-center">
    <div class="col-sm-12 col-md-11 col-lg-9 table--wrap">
      <div class="d-flex align-items-center">
        <h1 class="bo--agence-title">Liste des agences</h1>
        <span class="bo--agence-title-notification info">Pas de synchronisation</span>
        <span class="bo--agence-title-count">{{total}} {{total>1 ? 'éléments' : 'élément'}}</span>
      </div>
      <div class="col-md-12">
        <button class="float-right btn btn-primary mt-1 ml-1" @click="createAgency" v-if="canCreateAgency"> Créer une Agence</button>
        <button class="float-right btn btn-outline-primary mt-1 ml-1" @click="refresh"><font-awesome-icon class="ic ml-1 mr-1" :icon="['fas', 'sync-alt']"/></button>
      </div>
      <Grid ref="grid"
            id="AgencyList"
            :columns="headerColumns"
            :dataList="agencyList"
            :sortColumn="sortColumn"
            :sortDirection="sortDirection"
            :currentPage="currentPage"
            :loading="loading"
            :total="total"
            :canEditOrDelete="canUpdateAgency"
            :canEdit="canUpdateAgency"
            :canDelete="canDeleteAgency"
            @updateCurrentPage="limitCo => limit = limitCo"
            @dataLoading="dataLoading"
            @editComponent="editAgency"
            @confirmRemove="confirmRemove"
            @changeHeaderAutocomplete="filterManager"
      />
    </div>
    <b-modal id="archive" hide-footer title="Archive" :centered="true">
        <template v-slot:modal-title>
          Êtes-vous sur de vouloir supprimer cette agence?
        </template>
        <b-button class="mt-3" variant="btn col-3 btn-outline-secondary" @click.prevent="$bvModal.hide('archive')">Annuler</b-button>
        <b-button class="mt-2" variant="btn offset-5 col-4 btn-outline-danger" @click.prevent="removeAgency">Supprimer</b-button>
      </b-modal>
  </div>
</template>

<script>
  import Grid from "@/components/Grid/Grid";
  import {MixinStructureHeader} from "@/components/Util/MixinHeader";
  import {mapState, mapActions} from 'vuex';
  import moment from 'moment';

  export default {
    name: "AgencyList",
    mixins: [MixinStructureHeader()],
    components: {
      Grid
    },
    data() {
      return {
        columns: [
          {name: "Structure", value: 'structure_name', column: 'structure.id', sortable: true},
          {name: "Agence", value: 'companyName', column: 'company_name', sortable: true},
          {name: "Code Postal", value: 'postalCode', column: 'postalCode', sortable: true},
          {name: "Ville", value: 'city', column: 'city', sortable: true},
          {name: "Téléphone", value: 'phoneNumber', column: 'phone_number', sortable: true},
          {name: "Date de création", value: 'createdDate', column: 'created_at', sortable: true},
          {name: "Créateur", value: 'createdUser', column: 'created_by', sortable: false},
          {name: "Date de modification", value: 'updatedDate', column: 'updated_at', sortable: true},
          {name: "Modifiée par", value: 'updatedUser', column: 'updated_by', sortable: false},
          {name: "Manager", value: 'managerUser', column: 'manager', sortable: true, /*type: 'header_autocomplete'*/},
          {name: "Statut", value: 'status', column: 'status', sortable: true, list: [{label: 'Tous', value: ""}, {label: 'Actif', value: 1}, {label: 'Inactif', value: 0}]}
        ],
        sortColumn: 'company_name',
        sortDirection: 'ASC',
        limit: 10,
        currentPage: 1,
        removeableAgency: null,
        userQuery: ''
      };
    },
    computed: {
      ...mapState("Agency", ["loading", "agencies", "total", "error"]),
      ...mapState("User", ["users", 'connectedUser']),
      //headerColumns(){},headerColumns is set in mixin MixinStructureHeader
      tdStyle() {
        //return `width:${(100 / this.columns.length).toFixed(1)}%`;
        return `width:110px`;
      },
      agencyList() {
        return this.agencies.map(function (item) {
          return {
            ...item,
            structure_name: item.structure ? item.structure.name :  null,
            createdUser: item.createdBy ? item.createdBy.firstName + ' ' + item.createdBy.lastName : null,
            updatedUser: item.updatedBy ? item.updatedBy.firstName + ' ' + item.updatedBy.lastName : null,
            managerUser: item.manager ? item.manager.firstName + ' ' + item.manager.lastName : null,
            createdDate: item.createdAt ? moment(item.createdAt).format('DD/MM/YYYY') : null,
            updatedDate: item.updatedAt ? moment(item.updatedAt).format('DD/MM/YYYY') : null,
            status: item.status ? 'Actif' : 'Inactif'
          }
        });
      },
      canCreateAgency: {
        get() {
          let storedPermissions = sessionStorage.getItem('permissions');
          let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

          return permissionsArray.includes('CAN_CREATE_AGENCIES');
        }
      },
      canUpdateAgency: {
        get() {
          let storedPermissions = sessionStorage.getItem('permissions');
          let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

          return permissionsArray.includes('CAN_UPDATE_AGENCIES');
        }
      },
      canDeleteAgency: {
        get() {
          let storedPermissions = sessionStorage.getItem('permissions');
          let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

          return permissionsArray.includes('CAN_DELETE_AGENCIES');
        }
      },
      /*headerlist() {
          const q = this.userQuery;
          return this.users.filter(function(e) {
              return e.lastName.toUpperCase().indexOf(q) >=0 || e.firstName.toUpperCase().indexOf(q) >=0
          });
      }*/
    },
    mounted: function () {
      this.limit = window.localStorage.getItem("limit") ? parseInt(window.localStorage.getItem("limit")) : 10;
      if (this.agencies.length == 0) {
        this.dataLoading({limit: this.limit, offset: this.limit * (this.currentPage -1)});
      }
      //this.getUserList({limit: 100000});
    },
    methods: {
      ...mapActions("Agency", ["getAgencyList", "deleteAgency"]),
      ...mapActions("User", ["getUserList"]),
      filterManager(q) {
          this.userQuery = q;
      },
      dataLoading(data) {
        this.getAgencyList(data);
      },
      refresh() {
        this.currentPage = 1;
        this.sortColumn = "company_name";
        this.sortDirection = "ASC";
        this.getAgencyList({
          limit: this.limit,
          offset: this.limit * (this.currentPage -1),
          sortColumn: this.sortColumn,
          sortDirection: this.sortDirection,
        });

        this.$refs.grid.refreshFilter();
      },
      editAgency(id) {
        this.$router.push({name: 'agency-form', params: {id: id}});
      },
      createAgency() {
        this.$router.push({name: 'agency-form'});
      },
      confirmRemove(agency) {
        this.removeableAgency = agency;
        this.$bvModal.show('archive');
      },
      async removeAgency() {
        this.$bvModal.hide('archive');
        await this.deleteAgency({id: this.removeableAgency.id});
        if (this.error === null) {
          this.$notify.success("L'agence a été archivée")
        } else {
          this.$notify.success("Impossible de supprimer l'agence")
        }
        this.removeableAgency = null;
      }
    }
  };
</script>