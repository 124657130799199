import {getAsApplicationJson} from "../../../api/Fetch";
import {API_URL} from "../../../components/Util/EnvVariable";

export const getFeatures = async () =>  {
    try {
        const response = await getAsApplicationJson(`${API_URL}/features`);
        const data = await response.json();
        sessionStorage.setItem('features', data.features);
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export default {
    getFeatures,
}