<template>
  <div class="container pt-4">
    <h5 class="eval-item--title">Etat de santé</h5>
    <div class="eval-item--card cr--context">
      <div class="cr--context--part" :key="general?.title">
        <div
          class="row"
          v-for="(input, index) in general.inputs"
          :key="input.id"
        >
          <div class="col-sm-12 col-md-8 cr_entry">
            <p>{{ input.label }}</p>
            <template v-if="index === general.inputs.length - 1">
              <textarea
                class="form-control"
                v-model="input.response"
              ></textarea>
            </template>
            <template v-else>
              <p class="response">{{ input.response || DEFAULT_VALUE }}</p>
            </template>
          </div>
        </div>
      </div>

      <h5 class="cr-health--title">CARDIO-RESPIRATOIRE</h5>
      <div class="cr--context--part" :key="cardio?.title">
        <div
          class="row"
          v-for="(input, index) in cardio.inputs"
          :key="input.id"
        >
          <div class="col-sm-12 col-md-8 cr_entry">
            <p>
              <span v-if="input.id.includes('_date')">Date </span
              >{{ input.label }}
            </p>
            <template v-if="index === cardio.inputs.length - 1">
              <textarea
                class="form-control"
                v-model="input.response"
              ></textarea>
            </template>
            <template v-else>
              <p class="response">{{ input.response || DEFAULT_VALUE }}</p>
            </template>
          </div>
        </div>
      </div>

      <h5 class="cr-health--title">NUTRITION</h5>
      <div class="cr--context--part" :key="nutrition?.title">
        <div
          class="row"
          v-for="(input, index) in nutrition.inputs"
          :key="input.id"
        >
          <div class="col-sm-12 col-md-8 cr_entry">
            <p>
              <span v-if="input.id.includes('_date')">Date </span
              >{{ input.label }}
            </p>
            <template v-if="index === nutrition.inputs.length - 1">
              <textarea
                class="form-control"
                v-model="input.response"
              ></textarea>
            </template>
            <template v-else>
              <p class="response">{{ input.response || DEFAULT_VALUE }}</p>
            </template>
          </div>
        </div>
      </div>

      <h5 class="cr-health--title">ELIMINATION</h5>
      <div class="cr--context--part" :key="elimination?.title">
        <div
          class="row"
          v-for="(input, index) in elimination.inputs"
          :key="input.id"
        >
          <div class="col-sm-12 col-md-8 cr_entry">
            <p>
              <span v-if="input.id.includes('_date')">Date </span
              >{{ input.label }}
            </p>
            <template v-if="index === elimination.inputs.length - 1">
              <textarea
                class="form-control"
                v-model="input.response"
              ></textarea>
            </template>
            <template v-else>
              <p class="response">{{ input.response || DEFAULT_VALUE }}</p>
            </template>
          </div>
        </div>
      </div>

      <h5 class="cr-health--title">MOBILITE</h5>
      <div class="cr--context--part" :key="mobilite?.title">
        <div
          class="row"
          v-for="(input, index) in mobilite.inputs"
          :key="input.id"
        >
          <div class="col-sm-12 col-md-8 cr_entry">
            <p>
              <span v-if="input.id.includes('_date')">Date </span
              >{{ input.label }}
            </p>
            <template v-if="Array.isArray(input.inputs) && input.inputs.length">
              <div
                v-if="
                  input.inputs.some((subInput) => subInput.id.includes('R_'))
                "
              >
                <p class="response">
                  {{
                    input.inputs.find((subInput) => subInput.id.includes("R_"))
                      .response || DEFAULT_VALUE
                  }}
                </p>
              </div>
            </template>
            <template v-else-if="index === mobilite.inputs.length - 1">
              <textarea
                class="form-control"
                v-model="input.response"
              ></textarea>
            </template>
            <template v-else>
              <p class="response">{{ input.response || DEFAULT_VALUE }}</p>
            </template>
          </div>
        </div>
      </div>

      <h5 class="cr-health--title">PEAU</h5>
      <div class="cr--context--part" :key="skin?.title">
        <div class="row" v-for="(input, index) in skin.inputs" :key="input.id">
          <div class="col-sm-12 col-md-8 cr_entry">
            <p>
              <span v-if="input.id.includes('_date')">Date </span
              >{{ input.label }}
            </p>
            <template v-if="index === skin.inputs.length - 1">
              <textarea
                class="form-control"
                v-model="input.response"
              ></textarea>
            </template>
            <template v-else>
              <p class="response">{{ input.response || DEFAULT_VALUE }}</p>
            </template>
          </div>
        </div>
      </div>

      <h5 class="cr-health--title">SENSORIEL</h5>
      <div class="cr--context--part" :key="sensoriel?.title">
        <div
          class="row"
          v-for="(input, index) in sensoriel.inputs"
          :key="input.id"
        >
          <div class="col-sm-12 col-md-8 cr_entry">
            <p>
              <span v-if="input.id.includes('_date')">Date </span
              >{{ input.label }}
            </p>
            <template v-if="Array.isArray(input.inputs) && input.inputs.length">
              <div
                v-if="
                  input.inputs.some((subInput) => subInput.id.includes('R_'))
                "
              >
                <p class="response">
                  {{
                    input.inputs.find((subInput) => subInput.id.includes("R_"))
                      .response || DEFAULT_VALUE
                  }}
                </p>
              </div>
            </template>
            <template v-if="index === sensoriel.inputs.length - 1">
              <textarea
                class="form-control"
                v-model="input.response"
              ></textarea>
            </template>
            <template v-else-if="!input.inputs">
              <p class="response">{{ input.response || DEFAULT_VALUE }}</p>
            </template>
          </div>
        </div>
      </div>

      <h5 class="cr-health--title">DOULEUR</h5>
      <div class="cr--context--part" :key="pain?.title">
        <div class="row" v-for="(input, index) in pain.inputs" :key="input.id">
          <div class="col-sm-12 col-md-8 cr_entry">
            <p>
              <span v-if="input.id.includes('_date')">Date </span
              >{{ input.label }}
            </p>
            <template v-if="index === pain.inputs.length - 1">
              <textarea
                class="form-control"
                v-model="input.response"
              ></textarea>
            </template>
            <template v-else>
              <p class="response">{{ input.response || DEFAULT_VALUE }}</p>
            </template>
          </div>
        </div>
      </div>

      <h5 class="cr-health--title">ANXIETE</h5>
      <div class="cr--context--part" :key="anxiete?.title">
        <div
          class="row"
          v-for="(input, index) in anxiete.inputs"
          :key="input.id"
        >
          <div class="col-sm-12 col-md-8 cr_entry">
            <p>
              <span v-if="input.id.includes('_date')">Date </span
              >{{ input.label }}
            </p>
            <template v-if="Array.isArray(input.inputs) && input.inputs.length">
              <div style="min-width: 150px;"
                v-if="
                  input.inputs.some((subInput) => subInput.id.includes('R_'))
                "
              >
                <p class="response">
                  {{
                    input.inputs.find((subInput) => subInput.id.includes("R_"))
                      .response || DEFAULT_VALUE
                  }}
                </p>
              </div>
            </template>
            <template v-if="index === anxiete.inputs.length - 1">
              <textarea
                class="form-control"
                v-model="input.response"
              ></textarea>
            </template>
            <template v-else>
              <p class="response">{{ input.inputs[7].response || DEFAULT_VALUE }}</p>
            </template>
          </div>
        </div>
      </div>

      <h5 class="cr-health--title">DEPRESSION</h5>
      <div class="cr--context--part" :key="depression?.title">
        <div
          class="row"
          v-for="(input, index) in depression.inputs"
          :key="input.id"
        >
          <div class="col-sm-12 col-md-8 cr_entry">
            <p>
              <span v-if="input.id.includes('_date')">Date </span
              >{{ input.label }}
            </p>
            <template v-if="index === depression.inputs.length - 1">
              <textarea
                class="form-control"
                v-model="input.response"
              ></textarea>
            </template>
            <template v-else>
              <p class="response">{{ input.response || DEFAULT_VALUE }}</p>
            </template>
          </div>
        </div>
      </div>

      <h5 class="cr-health--title">COGNITION</h5>
      <div class="cr--context--part" :key="cognition?.title">
        <div
          class="row"
          v-for="(input, index) in cognition.inputs"
          :key="input.id"
        >
          <div class="col-sm-12 col-md-8 cr_entry">
            <p>
              <span v-if="input.id.includes('_date')">Date </span
              >{{ input.label }}
            </p>
            <template v-if="Array.isArray(input.inputs) && input.inputs.length">
              <div
                v-if="
                  input.inputs.some((subInput) => subInput.id.includes('R_'))
                "
              >
                <p class="response">
                  {{
                    input.inputs.find((subInput) => subInput.id.includes("R_"))
                      .response || DEFAULT_VALUE
                  }}
                </p>
              </div>
            </template>
            <template v-if="index === cognition.inputs.length - 1">
              <textarea
                class="form-control"
                v-model="input.response"
              ></textarea>
            </template>
            <template v-else-if="!input.inputs">
              <p class="response">{{ input.response || DEFAULT_VALUE }}</p>
            </template>
          </div>
        </div>
      </div>

      <h5 class="cr-health--title">POLYMEDICATION</h5>
      <div class="cr--context--part" :key="polymedication?.title">
        <div
          class="row"
          v-for="(input, index) in polymedication.inputs"
          :key="input.id"
        >
          <div class="col-sm-12 col-md-8 cr_entry">
            <p>
              <span v-if="input.id.includes('_date')">Date </span
              >{{ input.label }}
            </p>
            <template v-if="index === polymedication.inputs.length - 1">
              <textarea
                class="form-control"
                v-model="input.response"
              ></textarea>
            </template>
            <template v-else>
              <p class="response">{{ input.response || DEFAULT_VALUE }}</p>
            </template>
          </div>
        </div>
      </div>

      <h5 class="cr-health--title">MEDICAMENTER</h5>
      <div class="cr--context--part" :key="medicamenter?.title">
        <div
          class="row"
          v-for="(input, index) in medicamenter.inputs"
          :key="input.id"
        >
          <div class="col-sm-12 col-md-8 cr_entry">
            <p>
              <span v-if="input.id.includes('_date')">Date </span
              >{{ input.label }}
            </p>
            <template v-if="index === medicamenter.inputs.length - 1">
              <textarea
                class="form-control"
                v-model="input.response"
              ></textarea>
            </template>
            <template v-else>
              <p class="response">{{ input.response || DEFAULT_VALUE }}</p>
            </template>
          </div>
        </div>
      </div>

      <h5 class="cr-health--title">APPAREIL ET DISPOSITIFS MEDICAUX</h5>
      <div class="row">
        <div class="col-sm-12">
          <table class="table pec-table">
            <thead>
              <tr>
                <th>Appareil et dispositifs</th>
                <th>Statut</th>
                <th>Date de pose</th>
                <th>Date de contrôle</th>
                <th>Date de retrait</th>
                <th>Note</th>
              </tr>
            </thead>
            <tbody>
            </tbody>
            <tbody>
            <tr v-for="(item, index) in appareilMedicaux" :key="index">
              <td>{{ item.libelle }}</td>
              <td>{{ item.status }}</td>
              <td>{{ item.posingDate }}</td>
              <td>{{ item.controlDate }}</td>
              <td>{{ item.collectDate }}</td>
              <td>{{ item.note }}</td>
            </tr>
            <tr>
              <td colspan="6"></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CRComponent from "./CRComponent";
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "CR",
  extends: CRComponent,
  data() {
    return {
      DEFAULT_VALUE: '--',
      general: [],
      cardio: [],
      nutrition: [],
      elimination: [],
      mobilite: [],
      skin: [],
      sensoriel: [],
      pain: [],
      anxiete: [],
      depression: [],
      cognition: [],
      polymedication: [],
      medicamenter: [],
      appareilMedicaux: []
    };
  },
  mounted() {
    const payload = {
      page: {
        folder: "sante",
        domains: [
          "general",
          "cardio",
          "nutrition",
          "elimination",
          "mobilite",
          "skin",
          "sensoriel",
          "pain",
          "anxiete",
          "depression",
          "cognition",
          "polymedication",
          "medicamenter",
        ],
        evaluationId: this.$route.params.evaluationId,
        issuesElement: [],
      }
    };
    this.loadData(payload);
  },
  methods: {
    ...mapActions("GenerateTemplate", ["getGenerateTemplate", "getGenerateAnswerTemplate"]),
    ...mapActions("AppareilMedicaux", ["getAppareilMedicaux"]),
    mapInputsWithResponses(inputs, responsesList) {
      return inputs.map(input => {
        const responses = responsesList?.filter(res => res.questionId === input.id);
        const responseValue = responses?.length > 0
            ? responses.map(res => res.responseValue).join(', ')
            : input.defaultComment;
        const nestedInputs = input.inputs ? this.mapInputsWithResponses(input.inputs, responsesList) : null;

        return {
          ...input,
          response: responseValue,
          inputs: nestedInputs
        };
      });
    },
    formatField(value, length = 30, defaultValue = " Non renseignée ") {
      value = value ?? defaultValue;

      if (value.length < length) {
        let totalPadding = length - value.length;
        let leftPadding = Math.floor(totalPadding / 2);
        let rightPadding = totalPadding - leftPadding;
        value = " ".repeat(leftPadding) + value + " ".repeat(rightPadding);
      }

      return value;
    },
    exportSynthesis() {
      let synthesis = {};

      const payload = {
        page: {
          folder: "social",
          domains: ["habitat"],
          evaluationId: this.$route.params.evaluationId,
        }
      };
      this.loadData(payload);

      synthesis = this.getSynthese(synthesis, this.general);
      synthesis = this.getSynthese(synthesis, this.cardio);
      synthesis = this.getSynthese(synthesis, this.nutrition);
      synthesis = this.getSynthese(synthesis, this.elimination);
      synthesis = this.getSynthese(synthesis, this.mobilite);
      synthesis = this.getSynthese(synthesis, this.mobilite.inputs[5]);
      synthesis = this.getSynthese(synthesis, this.mobilite.inputs[6]);
      synthesis = this.getSynthese(synthesis, this.mobilite.inputs[7]);
      synthesis = this.getSynthese(synthesis, this.skin);
      synthesis = this.getSynthese(synthesis, this.sensoriel);
      synthesis = this.getSynthese(synthesis, this.sensoriel.inputs[8]);
      synthesis = this.getSynthese(synthesis, this.pain);
      synthesis = this.getSynthese(synthesis, this.anxiete);
      synthesis = this.getSynthese(synthesis, this.anxiete.inputs[0]);
      synthesis = this.getSynthese(synthesis, this.depression);
      synthesis = this.getSynthese(synthesis, this.cognition);
      synthesis = this.getSynthese(synthesis, this.cognition.inputs[2]);
      synthesis = this.getSynthese(synthesis, this.polymedication);
      synthesis = this.getSynthese(synthesis, this.medicamenter);

      synthesis["drugsDetailsNumber"] = this.drugsDetails.number;
      synthesis["miniCogResult"] = this.cognition.inputs[2].inputs[3].response.match(/\d+/)?.[0];
      synthesis["drugsDetailsGestion"] = this.drugsDetails.moments;
      synthesis["otherDrugs"] = this.drugsDetails.gestion;
      synthesis["drugsDescription"] = this.drugsDetails.otherDrugs;
      synthesis["doctorFrequency"] = this.drugsDetails.description;
      synthesis["doctorIsTraitant"] = this.doctorDetails.frequency;
      synthesis["doctorLastDoctor"] = this.doctorDetails.isTraitant;
      synthesis["healthWorkersVisits"] = this.doctorDetails.lastDoctor;
      synthesis["healthWorkersCareType"] = this.healthWorkers.visits;
      synthesis["healthWorkersOthers"] = this.healthWorkers.careType;
      synthesis["healthComment"] = this.healthWorkers.otherIntervenant;
      synthesis["appareilDispositif"] = this.appareilMedicaux.map(item => this.formatField(item.libelle)).join("\n");
      synthesis["appareilDispositifStatus"] = this.appareilMedicaux.map(item => this.formatField(item.status)).join("\n");
      synthesis["appareilDispositifPose"] = this.appareilMedicaux.map(item => this.formatField(item.posingDate)).join("\n");
      synthesis["appareilDispositifControle"] = this.appareilMedicaux.map(item => this.formatField(item.controlDate)).join("\n");
      synthesis["appareilDispositifRetrait"] = this.appareilMedicaux.map(item => this.formatField(item.collectDate)).join("\n");
      synthesis["appareilDispositifNote"] = this.appareilMedicaux.map(item => this.formatField(item.note)).join("\n");

      return synthesis;
    },
    getSynthese(synthesis, arraySante) {
      arraySante.inputs.forEach(input => {
        synthesis[input.id] = input.response || 'Non renseigné';
        synthesis[input.id + '-label'] = input.label || '';
      });

      return synthesis;
    },
    getData(numberPlace, data, responses) {
      return data.map(section => {
        return {
          ...section,
          inputs: this.mapInputsWithResponses(section.inputs, responses[numberPlace]?.responsesList ?? [])
        };
      })[numberPlace];
    },
    async loadData(payload) {
      try {
        const data = await this.getGenerateTemplate({payload});
        const responses = await this.getGenerateAnswerTemplate({payload});

        if (Array.isArray(data)) {
          this.general = this.getData(0, data, responses);
          this.cardio = this.getData(1, data, responses);
          this.nutrition = this.getData(2, data, responses);
          this.elimination = this.getData(3, data, responses);
          this.mobilite = this.getData(4, data, responses);
          this.skin = this.getData(5, data, responses);
          this.sensoriel = this.getData(6, data, responses);
          this.pain = this.getData(7, data, responses);
          this.anxiete = this.getData(8, data, responses);
          this.depression = this.getData(9, data, responses);
          this.cognition = this.getData(10, data, responses);
          this.polymedication = this.getData(11, data, responses);
          this.medicamenter = this.getData(12, data, responses);
        } else {
          console.error('data n\'est pas un tableau', data);
        }
      } catch (error) {
        console.error('Erreur lors du chargement des données :', error);
      }
    },
    async loadAppareilMedicaux(idClient) {
      this.appareilMedicaux = await this.getAppareilMedicaux({idClient: idClient});
    }
  },
  watch: {
    client(newClient) {
      if (newClient && newClient.id) {
        this.loadAppareilMedicaux(newClient.id)
      }
    }
  },
  computed: {
    ...mapState("Evaluation", [
      "evaluation",
      "treatment",
      "loading",
    ]),
    ...mapState('Client', ['client']),
    ...mapGetters("Material", ["getMaterials"]),
    ...mapGetters("AggirQuestion", [
      "getScoreQuestionAggirMoveOut",
      "getScoreQuestionAggirMoveIn",
      "getScoreQuestionAggirTransfert",
      "getScoreQuestionAggirOrientationTemps",
      "getScoreQuestionAggirOrientationEspace",
      "getScoreQuestionAggirCoherenceComportement",
      "getScoreQuestionAggirCoherenceCommunication",
      "getScoreQuestionAggirEliminationFecale",
      "getScoreQuestionAggirEliminationUrinaire",
    ]),
    aggirTraitement() {
      return this.getAggirAnswerScore(13)
          ? this.getAggirAnswerScore(13)
          : this.DEFAULT_VALUE;
    },
    healthComment: {
      get() {
        return this.evaluation.evaluatorComments
            ? this.evaluation.evaluatorComments.healthComment
            : "";
      },
      set(value) {
        this.evaluation.evaluatorComments.healthComment = value;
      },
    },
    drugsDetails() {
      const stsm01 = this.treatment.stsm01.response
          ? this.treatment.stsm01.response
          : this.DEFAULT_VALUE;
      /*const stsm01_bis = this.treatment.stsm01_bis.response ? this.treatment.stsm01_bis.response : this.DEFAULT_VALUE;*/
      const stsm02 = this.treatment.stsm02.response
          ? this.treatment.stsm02.response
          : this.DEFAULT_VALUE;
      const stsm03 = this.treatment.stsm03.response
          ? this.treatment.stsm03.response
          : this.DEFAULT_VALUE;
      const stsm04 = this.treatment.stsm04.response
          ? this.treatment.stsm04.response
          : this.DEFAULT_VALUE;
      const stsm05 = this.treatment.stsm05.response
          ? this.treatment.stsm05.response
          : this.DEFAULT_VALUE;

      const moments = {
        r1: "Matin",
        r2: "Midi",
        r3: "Après midi",
        r4: "Soir",
        r5: "Au coucher",
        r6: "La nuit",
        r7: "NSP",
      };

      const gestion = {
        r1: "Utilisation d'un semainier",
        r2: "Préparé par la personne elle-même",
        r3: "Préparé par une infirmière",
        r4: "Préparé par un membre de la famille",
        r5: "Médicaments pris seul(e)",
        r6: "Aide à la prise par une infirmière",
        r7: "Aide à la prise par un membre de la famille",
        r8: "Aide a la prise par un aidant professionnel",
      };

      const drugs = {
        r1: "0",
        r2: "1",
        r3: "2",
        r4: "3",
        r5: "4",
        r6: "5",
        r7: "5+",
        r8: "NSP",
      };

      return {
        number:
            stsm01 !== this.DEFAULT_VALUE
                ? this.stringifyCheckboxValues(stsm01, drugs)
                : "--",
        moments:
            stsm02 !== this.DEFAULT_VALUE
                ? this.stringifyCheckboxValues(stsm02, moments)
                : stsm02,
        gestion:
            stsm03 !== this.DEFAULT_VALUE
                ? this.stringifyCheckboxValues(stsm03, gestion)
                : stsm03,
        otherDrugs:
            stsm04 === "r1"
                ? "Oui"
                : stsm04 === "r2"
                    ? "Non"
                    : stsm04 === "r3"
                        ? "NSP"
                        : stsm04,
        description: stsm05,
      };
    },
    doctorDetails() {
      const stsm06 = this.treatment.stsm06.response
          ? this.treatment.stsm06.response
          : this.DEFAULT_VALUE;
      const stsm07 = this.treatment.stsm07.response
          ? this.treatment.stsm07.response
          : this.DEFAULT_VALUE;
      const stsm08 = this.treatment.stsm08.response
          ? this.treatment.stsm08.response
          : this.DEFAULT_VALUE;

      const frequency = {
        r1: "Chaque semaine",
        r2: "Chaque mois",
        r3: "Plusieurs fois par an",
        r4: "Une fois par an",
        r5: "Jamais",
        r6: "NSP",
      };

      const lastDoctor = {
        r1: "Dentiste",
        r2: "Ophtalmologue",
        r3: "ORL",
        r4: "Autre spécialiste",
        r5: "NSP",
      };

      return {
        frequency: stsm06 !== this.DEFAULT_VALUE ? frequency[stsm06] : stsm06,
        isTraitant:
            stsm07 === "r1"
                ? "Oui"
                : stsm07 === "r2"
                    ? "Non"
                    : stsm07 === "r3"
                        ? "NSP"
                        : stsm07,
        lastDoctor:
            stsm08 !== this.DEFAULT_VALUE
                ? this.stringifyCheckboxValues(stsm08, lastDoctor)
                : stsm08,
      };
    },
    healthWorkers() {
      const stsm09 = this.treatment.stsm09.response
          ? this.treatment.stsm09.response
          : this.DEFAULT_VALUE;
      const stsm10 = this.treatment.stsm10.response
          ? this.treatment.stsm10.response
          : this.DEFAULT_VALUE;
      const stsm11 = this.treatment.stsm11.response
          ? this.treatment.stsm11.response
          : this.DEFAULT_VALUE;

      const otherIntervenant = {
        r1: "Pédicure",
        r2: "Kinésithérapeute",
        r3: "Orthophoniste",
        r4: "Psychologue",
        r5: "Aucun de tout cela",
      };

      return {
        visits:
            stsm09 === "r1"
                ? "Oui"
                : stsm09 === "r2"
                    ? "Non"
                    : stsm09 === "r3"
                        ? "NSP"
                        : stsm09,
        careType: stsm10,
        otherIntervenant:
            stsm11 !== this.DEFAULT_VALUE
                ? this.stringifyCheckboxValues(stsm11, otherIntervenant)
                : stsm11,
      };
    },
  },
};
</script>
