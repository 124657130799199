<template class="accueil">
    <div class="accueil--content">
        <h2 class="accueil--title-one">Bienvenue sur OBY</h2>
        <h3 class="accueil--title-two">Votre outil d'évaluation</h3>
        <form class="accueil--form">
            <div class="mt-auto">
                <div class="p-2">
                    <label
                    >Vous évaluez
                    <font-awesome-icon
                        v-show="loading"
                        :icon="['fas', 'spinner']"
                        spin
                    />
                    </label>
                    <div class="row">
                        <div class="col-6">
                            <form-input
                                v-model="lastName"
                                label="Nom"
                                :login-input="true"
                                :disabled="false"
                            />
                        </div>
                        <div class="col-6">
                            <form-input
                                v-model="firstName"
                                label="Prénom(s)"
                                :login-input="true"
                                :disabled="false"
                            />
                        </div>
                      <div class="col-12">
                        <FormDatePicker v-model="birthDate"
                                        label="Date de naissance"
                                        :value ="birthDate"
                                        :login-input="true"
                        >
                        </FormDatePicker>
                      </div>
                    </div>
                    <div class="result__select"  v-if="!edit">
                        <div class="result__content">
                            <div class="result__card result__card--select">
                                <p class="result__text">
                                    <span class="result__number">Usager N°{{ selectedClient.id }} </span>
                                    <span class="result__label">N.Ut. </span>{{ selectedClient.lastname }}
                                    <span class="result__label">Pr.Ut. </span>{{ selectedClient.firstname }}
                                    <span class="result__label">S. </span><span class="result__normal">{{ getSex(selectedClient.sex) }}</span>
                                </p>
                                <p class="result__text">
                                    <span class="result__label">N. </span>{{ selectedClient.birthName }}
                                    <span class="result__label">Pr. Nais </span>{{ selectedClient.birthFirstname }}
                                    <span class="result__label">DDN. </span>{{ getBirthdate(selectedClient) }}
                                    <span class="result__label">Lieu Nais.</span>{{ selectedClient.birthCity }}
                                    <span class="result__label">INSEE Nais. </span>{{ selectedClient.codeInsee }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="result" :class="{ 'result__overflow': isOverflow }"  v-else>
                        <div class="result__container">
                            <div class="result__content">
                                <p class="result__card" v-for="item in clientsList"  @click.prevent="onSelect(item.id)" :key="item.id">
                                    <span class="result__number">Usager N°{{ item.id }} </span>
                                    <span class="result__label">N.Ut. </span>{{ item.lastname }}
                                    <span class="result__label">Pr.Ut. </span>{{ item.firstname }}
                                    <span class="result__label">S. </span><span class="result__normal">{{ getSex(item) }}</span>
                                    <span class="result__label">N. Nais </span>{{ item.birth_name }}
                                    <span class="result__label">Pr. Nais </span>{{ item.birth_firstname }}
                                    <span class="result__label">DDN. </span>{{ getBirthdate(item) }}
                                    <span class="result__label">Lieu Nais.</span>{{ item.birthCity }}
                                    <span class="result__label">INSEE Nais. </span>{{ item.code_insee }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!edit" role="button" class="mt-auto d-flex justify-content-center" @click.prevent="showEvaluationModal">
                <p class="poppin">{{ getCount(selectedClient) }} {{ getCount(selectedClient) > 1 ? 'évaluations' : 'évaluation' }}</p>
            </div>
          <div :style="displayOpenMaps">
            <div class="mt-auto d-flex justify-content-center">
              <button
                  class="btn btn-outline-primary btn-lg"
                  :disabled="disabled"
                  style="min-width: 187px; width: 250px"
                  @click.prevent="openMaps"
              >
                Lancer l'itinéraire
              </button>
            </div>
          </div>
            <div class="mt-3 d-flex justify-content-center">
                <button
                    class="btn btn-primary btn-lg"
                    style="min-width: 187px; width: 250px"
                    :disabled="disabled"
                    @click.prevent="beginEvaluation"
                    v-if="accessBeginEvaluation"
                >
                    Démarrer l'évaluation
                </button>
            </div>
            <div class="mt-3 d-flex justify-content-center">
                <button
                    class="btn btn-primary btn-lg"
                    style="min-width: 187px; width: 250px"
                    :disabled="false"
                    @click.prevent="showCreateEvaluation"
                >
                    Créer un nouveau bénéficiaire
                </button>
            </div>
        </form>
    </div>
</template>
<script>
    import Moment from "moment";
    import FormDatePicker from "@/components/Form/DatePicker.vue";
    import { mapActions, mapState } from "vuex";
    export default {
        name: 'FormEvaluation',
        data() {
            return {
                firstName: null,
                lastName: null,
                birthDate: null,
                loading: false,
                list: null,
                edit: true,
                resetInit: true
            }
        },
        watch: {
            firstName: 'onInputUser',
            lastName: 'onInputUser',
            birthDate: 'onInputUser'
        },
        mounted() {
            window.addEventListener('keydown', this.handleEnterKey);
        },
        beforeDestroy() {
            window.removeEventListener('keydown', this.handleEnterKey);
        },
        methods: {
            ...mapActions("Client",["getClientSearch"]),
            onInputUser() {
                this.disabled = true;
                this.resetInit = false;
                const payload = {
                    lastname: this.lastName === "" ? null : this.lastName,
                    firstname: this.firstName === "" ? null : this.firstName,
                    birthDate: this.birthDate === null ? null : this.birthdateTransform(this.birthDate)
                }

                // Disable launch maps, search client and create client
                if (payload.birthDate || payload.firstname || payload.lastname) {
                  this.disabled = true;
                }

                this.getClientSearch({ payload: payload});
                this.edit = true;
            },
            handleEnterKey(event) {
                if (event.key === 'Enter') {
                    event.preventDefault();
                }
            },
            birthdateTransform(value) {
                const formatedDate = Moment(value).format('YYYY/MM/DD');

                if (formatedDate == "Invalid date") {

                    return null;
                }

                return formatedDate;
            },
            birthdateFr(value) {
                const formatedDate = Moment(value).format('DD/MM/YYYY');

                if (formatedDate == "Invalid date") {

                    return null;
                }

                return formatedDate;
            },
            onSelect(id) {
                this.edit = false;
                this.$emit("onSelect", id);
            },
            getSex(item) {
                return item.sex === 'F' ? 'féminin' : 'masculin';
            },
            getCount(selectedClient) {
                return selectedClient?.evaluationNumber ? selectedClient?.evaluationNumber : 0
            },
            getBirthdate(selectedClient) {
                return selectedClient.birth_date?.date ? this.birthdateFr(selectedClient.birth_date.date) : null;
            }
        },
        props: {
            showCreateEvaluation: {
                type: Function,
                default: null
            },
            beginEvaluation: {
                type: Function,
                default: null
            },
            showEvaluationModal: {
              type: Function,
              default: null
            },
            openMaps: {
                type: Function,
                default: null
            },
            disabled: {
                type: Boolean,
                default: false
            },
            selectedClient: {
                type: Object,
                default: null
            }
        },
        components: {
            FormDatePicker
        },
        computed: {
            ...mapState("Client", ["clients"]),
            clientsList() {
                return this.resetInit ? [] : this.clients
            },
          accessBeginEvaluation: {
            get() {
              let storedPermissions = sessionStorage.getItem('permissions');
              let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

              return permissionsArray.includes('CAN_BEGIN_EVALUATION');
            }
          },
          displayOpenMaps() {
            return {
              display: this.disabled ? 'none' : 'block',
            };
          },
            isOverflow() {
                return this.resetInit ? false : this.clients?.length > 3;
            }
        }
    }
</script>

<style scoped>
    ::-webkit-scrollbar {
        width: 10px;
        height: 700px;
    }

    ::-webkit-scrollbar-track {
        background:  #D9D9D9;
    }

    ::-webkit-scrollbar-thumb {
        background: #4164E3;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #4164e3b3;
    }
</style>