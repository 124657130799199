<template>
    <div class="container pt-4">
      <h5 class="eval-item--title">Entourage et participation sociale</h5>
      <div class="eval-item--card cr--context">
        <div class="row">
          <div class="col-sm-12 col-md-6 cr_entry">
            <p>Situation familiale :</p>
            <p class="response">{{familySituation}}</p>
          </div>
          <div class="col-sm-12 col-md-6 cr_entry">
            <p>Mode de vie :</p>
            <p class="response">{{lifeMode}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-6 cr_entry">
            <p>Mesure de protection :</p>
            <p class="response">{{protectionMeasure}}</p>
          </div>
          <div class="col-sm-12 col-md-6 cr_entry" v-if="coordinates.display">
            <p>Coordonnées :</p>
            <p class="response">{{coordinates.resp}}</p>
          </div>
        </div>

        <div class="row" v-if="pets.display">
          <div class="col-sm-12 col-md-6 cr_entry">
            <p>Animal de compagnie :</p>
            <p class="response">{{pets.resp}}</p>
          </div>
          <div class="col-sm-12 col-md-6 cr_entry">
            <p>Précautions a prendre vis-à-vis de l'animal de compagnie :</p>
            <p class="response">{{petPrecaution}}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 cr_entry">
            <p>Sentiment de solitude ou d’isolement :</p>
            <p class="response">{{feelingLoneliness}}</p>
          </div>
          <div class="col-sm-12 col-md-6 cr_entry">
            <p>Visite des proches :</p>
            <p class="response">{{visitsRelatives}}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 cr_entry">
            <p>Activités de loisirs au domicile :</p>
            <p class="response">{{homeActivities}}</p>
          </div>
          <div class="col-sm-12 cr_entry">
            <p>Description des activités au domicile :</p>
            <p class="response">{{homeActivitiesDescription}}</p>
          </div>
          <div class="col-sm-12 cr_entry">
            <p>Activités de loisirs à l'exterieur :</p>
            <p class="response">{{outdoorActivities}}</p>
          </div>
          <div class="col-sm-12 cr_entry">
            <p>Description des activités à l'extérieur :</p>
            <p class="response">{{outdoorActivitiesDescription}}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6 cr_entry">
            <p>Souhait d'autres activités :</p>
            <p class="response">{{activitiesWishes}}</p>
          </div>
          <div class="col-sm-12 cr_entry" v-if="wishesList.display">
            <p>Si oui lesquelles ?</p>
            <p class="response">{{wishesList.resp}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <p class="mb-0">Commentaires de l'évaluateur sur l'entourage et la participation sociale :</p>
            <form-textarea
                v-model="entourageComment"
                :rows="3"
                class="w-100"
                :login-input="true"
                :disabled="false"
                @blur="changeCommentaryValue()" />
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import CRComponent from "./CRComponent";
import {mapMutations} from "vuex";
import {mapState} from "vuex";

export default {
  name: "CR_Entourage",
  extends: CRComponent,
  methods: {
    ...mapMutations('Evaluation', ['setEvaluationProperty']),
    exportSynthesis() {
      return {
        familySituation: this.familySituation,
        lifeMode: this.lifeMode,
        protectionMeasure: this.protectionMeasure,
        coordinates: this.coordinates.display ? this.coordinates.resp : this.DEFAULT_VALUE,
        pets: this.pets.display ? this.pets.resp : "Non concerné",
        petPrecaution: this.pets.display ? this.petPrecaution : "Non concerné",
        homeActivities: this.homeActivities,
        homeActivitiesDescription: this.homeActivitiesDescription,
        outdoorActivities: this.outdoorActivities,
        outdoorActivitiesDescription: this.outdoorActivitiesDescription,
        activitiesWishes: this.activitiesWishes,
        wishesList: this.wishesList.display ? this.wishesList.resp : "Non concerné",
        entourageComment: this.entourageComment,
        feelingLoneliness: this.feelingLoneliness,
        visitsRelatives: this.visitsRelatives
      }
    }
  },
  created() {
    this.DEFAULT_VALUE = '--';
  },
  computed: {
    ...mapState("Client", ["client"]),
    familySituation() {
      return this.getQuestionAnswers(25).list.join(', ');
    },
    lifeMode() {
      return this.getQuestionAnswers(26).list.join(', ');
    },
    protectionMeasure() {
      return this.getQuestionAnswers(27).list.join(', ');
    },
    coordinates() {
      const cond = this.getQuestionAnswers(27).responsesIds;

      if (cond && (cond.includes(4) || cond.includes(5) || cond.includes(6) || cond.includes(7))) {
        const contactId = this.getQuestionAnswers(28).list.join(', ').trim();
        let isReferent = '';
        if (this.client.referent) {
          if (this.client.referent.id == contactId) {
            isReferent = ', personne référente';
          }
        }
        const contact = this.client.contactList.find(e=>e.id == contactId);
        return {
          resp: contact ? contact.firstName + ' ' + contact.lastName + ', ' + contact.phone + isReferent : this.DEFAULT_VALUE,
          display: true
        }
      }
      return {
        resp: null,
        display: false
      }
    },
    pets() {
      const cond = this.getQuestionAnswers(29);

      if (cond && cond.responsesIds && cond.responsesIds.includes(0)) {
        return {
          resp: cond.list['0'],
          display: true
        }
      }
      return {
        resp: null,
        display: false
      }
    },
    petPrecaution() {
      return this.getQuestionAnswers(30).list.join(', ');
    },
    feelingLoneliness() {
      return this.getQuestionAnswers(36).list.join(', ');
    },
    visitsRelatives() {
      return this.getQuestionAnswers(31).list.join(', ');
    },
    homeActivities() {
      return this.getQuestionAnswers(32).list.join(', ');
    },
    homeActivitiesDescription() {
      return this.getQuestionAnswers(67).list.join(', ');
    },
    outdoorActivities() {
      return this.getQuestionAnswers(33).list.join(', ');
    },
    outdoorActivitiesDescription() {
      return this.getQuestionAnswers(68).list.join(', ');
    },
    activitiesWishes() {
      return this.getQuestionAnswers(34).list.join(', ');
    },
    wishesList() {
      const cond = this.getQuestionAnswers(34);

      if (cond && cond.responsesIds && (cond.responsesIds.includes(0) || cond.responsesIds.includes(2))) {
        return {
          resp: this.getQuestionAnswers(35).list.join(', '),
          display: true
        }
      }
      return {
        resp: null,
        display: false
      }
    },
    evaluatorCommentary() {
      return this.getQuestionAnswers(35).list.join(', ');
    },
    entourageComment: {
      get() {
        return this.getQuestionAnswers(78).list.join(', ');
      },
      set(value) {
        this.evaluation.evaluatorComments.entourageComment = value;
      }
    }
  }
};
</script>