<template>
  <div>
    <textarea
        v-model="text"
        class="form-control"
        placeholder="Saisir une réponse"
        style="width: 100%;max-width: 100%;"
        rows="5"
        @blur="updateText"></textarea>
  </div>
</template>
<script>

export default {
  name: "GoalsFreeText",
  components: {
  },
  props: {
    inputData: {
      type: Object,
      required: true,
    },
    category: {
      type: String,
      required: null,
    },
    noActionSelected: {
      type: Boolean,
      required: false,
    },
    field: {
      type: String,
      required: true
    }
  },
  created() {
  },
  data() {
    return {
      active: false
    };
  },
  methods: {
    updateText() {
      this.$emit('updateFreeText', {
        ...this.inputData,
        selectedAffectation: typeof this.inputData.affectation === "string" ? this.inputData.affectation: this.inputData.affectation.selected,
      });
    }
  },
  computed: {
    text: {
      get() {
        if(this.field === 'actions') {return this.inputData.actions; }
        if(this.field === 'affectation') { return this.inputData.affectation;}
        if(this.field === 'problem') { return this.inputData.problem;}
        return this.noActionSelected ? ' ' : this.inputData.goal;
      },
      set(value) {
        if(this.field === 'actions') {
          this.inputData.actions = value;
        } else if(this.field === 'affectation') {
          this.inputData.affectation = value;
        } else if(this.field === 'problem') {
          this.inputData.problem = value;
        } else {
          this.inputData.goal = value;
        }

      }
    }
  }
};
</script>