<template>
  <div class="bo--agence row mx-0 justify-content-center">
    <div class="col-sm-12 col-md-11 col-lg-9 table--wrap">
      <div class="d-flex align-items-center">
        <h1 class="bo--agence-title">Liste des Matériels</h1>
        <span class="bo--agence-title-notification info">Pas de synchronisation</span>
        <span class="bo--agence-title-count">{{total}} {{total>1 ? 'éléments' : 'élément'}}</span>
      </div>
      <div class="col-md-12">
        <button class="float-right btn btn-primary mt-1 ml-1" v-if="canCreateMaterial" @click="createMaterial"> Créer un materiel</button>
        <button class="float-right btn btn-outline-primary mt-1 ml-1" @click="refresh"><font-awesome-icon class="ic ml-1 mr-1" :icon="['fas', 'sync-alt']"/></button>
      </div>
      <Grid ref="grid"
            id="MaterialList"
            :columns="headerColumns"
            :dataList="materialList"
            :sortColumn="sortColumn"
            :sortDirection="sortDirection"
            :currentPage="currentPage"
            :loading="loading"
            :total="total"
            :canEditOrDelete="true"
            :canEdit="canUpdateMaterial"
            :canDelete="canDeleteMaterial"
            @updateCurrentPage="limitCo => limit = limitCo"
            @dataLoading="dataLoading"
            @editComponent="editMaterial"
            @confirmRemove="confirmRemove"
            @changeHeaderAutocomplete="filterManager"
      />
    </div>
    <b-modal id="archive" hide-footer title="Archive" :centered="true">
      <template v-slot:modal-title>
        Êtes-vous sur de vouloir archiver ce materiel ?
      </template>
      <b-button class="mt-3" variant="btn col-3 btn-outline-secondary" @click.prevent="$bvModal.hide('archive')">Annuler</b-button>
      <b-button class="mt-2" variant="btn offset-6 col-3 btn-outline-danger" @click.prevent="removeMaterial">Archiver</b-button>
    </b-modal>
  </div>
</template>

<script>
  import Grid from "@/components/Grid/Grid";
  import {MixinStructureHeader} from "@/components/Util/MixinHeader";
  import {mapState, mapActions, mapGetters} from 'vuex';
  import moment from 'moment';

  export default {
    name: "MaterialList",
    mixins: [MixinStructureHeader()],
    components: {
      Grid
    },
    data() {
      return {
        columns: [
          {name: "Nom", value: 'name', column: 'name', sortable: true},
          {name: "Date de création", value: 'createdDate', column: 'created_at', sortable: true},
          {name: "Créateur", value: 'createdUser', column: 'created_by', sortable: false},
          {name: "Date de modification", value: 'updatedDate', column: 'updated_at', sortable: true},
          {name: "Modifiée par", value: 'updatedUser', column: 'updated_by', sortable: false},
          {name: "Statut", value: 'status', column: 'status', sortable: true, list: [{label: 'Tous', value: ""}, {label: 'Actif', value: 1}, {label: 'Inactif', value: 0}]}
        ],
        sortColumn: 'name',
        sortDirection: 'ASC',
        limit: 10,
        currentPage: 1,
        removeableMaterial: null,
        userQuery: ''
      };
    },
    computed: {
      ...mapState("Material", ["loading", "materials", "total", "error"]),
      ...mapState("User", ["users", 'connectedUser']),
      ...mapGetters('User', ['isUserRoleOperationManager']),
      //headerColumns(){},headerColumns is set in mixin MixinStructureHeader
      tdStyle() {
        //return `width:${(100 / this.columns.length).toFixed(1)}%`;
        return `width:110px`;
      },
      canCreateMaterial: {
        get() {
          let storedPermissions = sessionStorage.getItem('permissions');
          let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

          return permissionsArray.includes('CAN_CREATE_MATERIAL');
        }
      },
      canUpdateMaterial: {
        get() {
          let storedPermissions = sessionStorage.getItem('permissions');
          let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

          return permissionsArray.includes('CAN_UPDATE_MATERIAL');
        }
      },
      canDeleteMaterial: {
        get() {
          let storedPermissions = sessionStorage.getItem('permissions');
          let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

          return permissionsArray.includes('CAN_DELETE_MATERIAL');
        }
      },
      materialList() {
        return this.materials.map(function (item) {
          return {
            ...item,
            createdUser: item.createdBy ? item.createdBy.firstName + ' ' + item.createdBy.lastName : null,
            updatedUser: item.updatedBy ? item.updatedBy.firstName + ' ' + item.updatedBy.lastName : null,
            createdDate: item.createdAt ? moment(item.createdAt).format('DD/MM/YYYY') : null,
            updatedDate: item.updatedAt ? moment(item.updatedAt).format('DD/MM/YYYY') : null,
            status: item.status ? 'Actif' : 'Inactif'
          }
        });
      },
      /*headerlist() {
          const q = this.userQuery;
          return this.users.filter(function(e) {
              return e.lastName.toUpperCase().indexOf(q) >=0 || e.firstName.toUpperCase().indexOf(q) >=0
          });
      }*/
    },
    mounted: function () {
      this.limit = window.localStorage.getItem("limit") ? parseInt(window.localStorage.getItem("limit")) : 10;
      if (this.materials.length == 0) {
        this.dataLoading({limit: this.limit, offset: this.limit * (this.currentPage -1)});
      }
    },
    methods: {
      ...mapActions("Material", ["getMaterialList", "deleteMaterial"]),
      ...mapActions("User", ["getUserList"]),
      filterManager(q) {
          this.userQuery = q;
      },
      dataLoading(data) {
        this.getMaterialList(data);
      },
      refresh() {
        this.currentPage = 1;
        this.sortColumn = "name";
        this.sortDirection = "ASC";
        this.getMaterialList({
          limit: this.limit,
          offset: this.limit * (this.currentPage -1),
          sortColumn: this.sortColumn,
          sortDirection: this.sortDirection,
        });

        this.$refs.grid.refreshFilter();
      },
      editMaterial(id) {
        this.$router.push({name: 'material-form', params: {id: id}});
      },
      confirmRemove(material) {
        this.removeableMaterial = material;
        this.$bvModal.show('archive');
      },
      createMaterial() {
        this.$router.push({name: 'material-form'});
      },
      async removeMaterial(){
        this.$bvModal.hide('archive');
        await this.deleteMaterial({id: this.removeableMaterial.id});
        if (this.error === null) {
          this.$notify.success("Le materiel a été archivé")
        } else {
          this.$notify.success("Impossible d'archiver le materiel")
        }
        this.removeableMaterial = null;
      }
    }
  };
</script>