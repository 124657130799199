<template>
  <div class="grid--table-wrap" ref="table-wrap">
    <div class="table--loading" v-if="loading" v-loading="loading"></div>
    <div class="d-flex justify-content-start">
      <div
          class="ml-1"
          v-for="(column, index) in headerColumns.filter(e=>e.filterOnly)"
          :key="`filter-only-${id}-${index}`">
        <template v-if="column.list && column.type === 'multi-select'">
          <el-select
            class="w-100"
            v-model="filterText[column.column]"
            multiple
            :placeholder="column.name"
            @change="updateFilter">
            <el-option
              v-for="item in column.list"
              :key="item+Math.random()"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </template>
        <template v-if="column.list && column.type === 'select'">
          <el-select
            v-model="filterText[column.column]"
            filterable
            :placeholder="column.name"
            @change="updateFilter">
            <el-option
              v-for="item in column.list"
              :key="item.label+Math.random()"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </template>
      </div>
    </div>
    <el-table class="table--bo" :class="{'has-status':hasStatus}"
            :data="dataList"
            @header-click="toggleFilter">
      <el-table-column
        :width="tdWidth"
        :prop="column.column"
        :label="column.name"
        v-for="(column, index) in headerColumns.filter(e=>!e.filterOnly)"
        :key="`thead-${id}-${index}`">
        <template v-slot:header>
          <template v-if="column.list">
            <el-select
              v-model="filterText[column.column]"
              filterable
              :placeholder="column.name"
              @change="updateFilter">
              <el-option
                v-for="item in column.list"
                :key="item.label+Math.random()"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </template>
          <template v-else-if="column.type === 'header_autocomplete'">
            <el-select
              v-model="filterText[column.column]"
              filterable
              remote
              :placeholder="column.name"
              @change="updateFilter"
              :remote-method="changeHeader">
              <el-option
                v-for="item in headerlist"
                :key="item.id"
                :label="item.firstName + ' ' + item.lastName"
                :value="item.id"
              ></el-option>
            </el-select>
          </template>
          <template v-else>
            <template
              v-if="((column.column !== 'updated_at')
              && column.column !== 'created_at'
              && column.column !== 'schedule'
              && column.column !== 'date_start'
              && column.column !== 'date_end')
              && column.sortable
              && (filterActive === column.column || filterText[column.column])">
              <div class="filter">
                <div class="input">
                  <input type="text"
                    v-focus
                    v-model="filterText[column.column]"
                    :placeholder="column.name"
                    class="form-control form-control-sm"
                    @input="updateFilter(column.column)"
                    @blur.prevent="filterActive = null"/>
                </div>
              </div>
            </template>
            <template v-else>
              <span class="cell-column-name">{{column.name}}</span>
            </template>
          </template>
          <span @click.stop="sortChange(column.column)" :class="[sortDirectionTemp && sortColumnTemp && column.column === sortColumnTemp ? (sortDirectionTemp === 'DESC' ? 'descending' : 'ascending') : '', 'caret-wrapper']">
              <i class="sort-caret ascending"></i>
              <i class="sort-caret descending"></i>
            </span>
        </template>
        <template slot-scope="scope">
          <template v-if="scope.row[column.value]">
            <div v-if="scope.row.statusSync==='Non synchronisé'" class="text-danger" :class="column.overflow == 'ellipsis' ? 'text-overflow' : ''"><span>{{scope.row[column.value]}}</span></div>
            <div v-else :class="column.overflow == 'ellipsis' ? 'text-overflow' : ''"><span>{{scope.row[column.value]}}</span></div>
          </template>
          <template v-else>
            <span class="empty-dot"></span>
          </template>
        </template>
      </el-table-column>
      <el-table-column width="110px" label="Action" fixed="right" v-if="!listOnly">
        <template slot-scope="scope">
          <div class v-if="!listOnly && canEditOrDelete">
            <font-awesome-icon
              v-if="canEdit"
              class="ic ic-primary ml-1 mr-1"
              :icon="['fas', 'pencil-alt']"
              @click.prevent="$emit('editComponent', scope.row.id)"/>
            <font-awesome-icon
              v-else-if="canOnlyDeleteUser"
              class="ic ic-primary ml-1 mr-1"
              :icon="['fas', 'eye']"
              @click.prevent="$emit('editComponent', scope.row.id)"/>
            <font-awesome-icon
              v-if="!readOnly && canDelete"
              class="ic ic-danger ml-4 mr-1"
              :icon="['fas', 'trash']"
              @click.prevent="$emit('confirmRemove', scope.row)"/>
          </div>
          <div class="cell pl-2" v-else>
              <font-awesome-icon class="ic ic-primary ml-1 mr-1" :icon="['fas', 'eye']" @click.prevent="$emit('editComponent', scope.row.id)"/>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      :total="total"
      :limit="limit"
      :currentPage.sync="currentPage"
      @changePage="updateCurrentPage"
      ref="pagination"
      class="mt-4"
    />
  </div>
</template>
<script>
import Pagination from "@/components/BO/Pagination";
import ClickOutside from "vue-click-outside";

export default {
  name: "Grid",
  components: {
    Pagination
  },
  props: {
    id: String,
    columns: Array,
    dataList: Array,
    headerlist: Array,
    sortColumn: String,
    sortDirection: String,
    loading: Boolean,
    total: Number,
    currentPage: Number,
    readOnly: {
      type: Boolean,
      default: false
    },
    listOnly: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: Boolean,
      default: true
    },
    canEditOrDelete: Boolean,
    canEdit: Boolean,
    canOnlyDeleteUser: Boolean,
    canDelete: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    headerColumns: [],
    localCurrentPage: 1,
    limit: 100,
    removeableAgency: null,
    filterActive: null,
    filterText: {},
    sortDirectionTemp: 'ASC',
    sortColumnTemp: null
  }),
  computed: {
    tdStyle() {
      return "width: 110px;max-width: 110px;min-width: 110px;";
    },
    hasStatus(){
      return this.headerColumns && this.headerColumns.filter(column=>column.column=='status' || column.name==='Statut').length > 0
    },
    tdWidth(){
      var w = this.$refs['table-wrap'].clientWidth /  (this.headerColumns.length + 1)
      if(w<150){
        return `200px`
      } else {
        return `${w}px`
      }
      // return this.headerColumns.length < 7 ? '200px' : '130px'
    }
  },
  methods: {
    async changeHeader(query) {
      this.$emit("changeHeaderAutocomplete", query.toUpperCase());
    },
    toggleFilter(column) {
      this.filterActive = this.filterActive === column.property ? null : column.property;
    },
    closeFilter() {
      this.filterActive = null;
    },
    sortChange(column){
      this.sortDirectionTemp = this.sortDirectionTemp === 'ASC' ? 'DESC' : 'ASC';
      this.sortColumnTemp = column;
      this.$emit("dataLoading", this.formatData());
    },
    updateFilter() {
      this.offset = 0;
      this.$emit("dataLoading", this.formatData());
    },
    updateCurrentPage({ limit, offset }) {
      this.limit = limit;
      window.localStorage.setItem("limit", limit);
      this.offset = offset;
      this.$emit("dataLoading", this.formatData());
      this.$emit("updateCurrentPage", limit);
    },
    formatData() {
      return {
        filters: this.convertArray(this.filterText),
        sortColumn: this.sortColumnTemp ? this.sortColumnTemp : this.sortColumn,
        sortDirection: this.sortDirectionTemp
          ? this.sortDirectionTemp
          : this.sortDirection,
        limit: this.limit,
        offset: this.offset
      };
    },
    convertArray(data) {
      let array = [];
      Object.keys(data).forEach(function(key) {
        array.push('{"column" : "' + key + '", "val" : "' + data[key] + '"}');
      });
      return array;
    },
    refreshFilter() {
      this.localCurrentPage = 1;
      this.$refs.pagination.resetPage();
      this.limit = window.localStorage.getItem("limit")
        ? parseInt(window.localStorage.getItem("limit"))
        : 100;
      this.removeableAgency = null;
      this.filterActive = null;
      this.filterText = {};
      this.sortDirectionTemp = null;
      this.sortColumnTemp = null;
    },
    clickOutSide() {},
    customTdStyle(width) {
      return `width: ${width}; min-width:${width}; max-width: ${width}`;
    },
    updateSortElement(column, id){
      let elements = document.getElementsByClassName("caret-wrapper");
      elements.forEach((elementToRemoveClass) => {
        elementToRemoveClass.classList.remove('ascending');
        elementToRemoveClass.classList.remove('descending');
      });

      let element = document.getElementById(id);
      if(!element){
        return;
      }

      this.sortDirectionTemp = this.sortDirectionTemp === 'ASC' ? 'ASC' : 'DESC';
      element.classList.remove('ascending');
      element.classList.remove('descending');
      element.classList.add(this.sortDirectionTemp === 'ASC' ? 'ascending' : 'descending');
    }
  },
  watch: {
    currentPage(value) {
      this.localCurrentPage = value;
    },
    columns(value) {
      this.headerColumns = value;
    }
  },
  mounted() {
    this.limit = window.localStorage.getItem("limit")
      ? parseInt(window.localStorage.getItem("limit"))
      : 100;
    this.headerColumns = this.columns;
  },
  directives: {
    ClickOutside,
    focus: {
      inserted(el) {
        el.focus();
      }
    }
  }
};
</script>