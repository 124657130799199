import { client as ApolloClient } from "@/api/Apollo";
import { postUpload, postAsApplicationJson, getAsApplicationJson } from "@/api/Fetch";
import { CLIENT_LIST } from "@/api/GraphQLQueries/bo/client/list-query";
import { GET_CLIENT } from "@/api/GraphQLQueries/bo/client/get-client-query";
import { GENERATE_CLIENT_QR_CODE } from "@/api/GraphQLQueries/bo/client/generate-client-qr-code-mutation";
import { SAVE_CLIENT } from "@/api/GraphQLQueries/bo/client/save-client-mutation";
import { REPORT_CLIENT } from "@/api/GraphQLQueries/bo/client/report-client-mutation";
import { DELETE_CLIENT } from "@/api/GraphQLQueries/bo/client/delete-client-mutation";
import { DELETE_CLIENT_DOCUMENT } from "@/api/GraphQLQueries/bo/client/delete-client-document-mutation";
import { ASSIGN_USER } from "@/api/GraphQLQueries/bo/client/assign-user-mutation";
import { SAVE_CLIENT_CONTACT } from "@/api/GraphQLQueries/bo/client/save-client-contact-mutation";
import { DELETE_CLIENT_CONTACT } from "@/api/GraphQLQueries/bo/client/delete-client-contact-mutation";
import { API_URL } from "@/components/Util/EnvVariable";
import Moment from "moment";

export const getClientSearch = async ({ commit }, { payload }) => {
  try {
    commit("setClientLoading");
    const response = await postAsApplicationJson(`${API_URL}/search-client`, payload);
    const data = await response.json();
    commit("setClientList", data.clients);
    commit("setTotalClientList", data.length);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getClientList = (
  { commit, state },
  {
    filters = "{}",
    sortColumn = "lastname",
    sortDirection = "ASC",
    limit = 10,
    offset = 0,
  }
) => {
  commit("setClientLoading");
  if (state.cancelPromise) {
    state.cancelPromise();
  }
  return new Promise((resolve, reject) => {
    state.cancelPromise = () => {
      ApolloClient.stop();
      reject("cancel token");
    };
    ApolloClient.query({
      query: CLIENT_LIST,
      variables: {
        filters: filters,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
        limit: limit,
        offset: offset,
      },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  })
    .then((res) => {
      commit("setClientList", res.data.clientList.items);
      commit("setTotalClientList", res.data.clientList.total);
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      if (err === "cancel token") {
        throw err;
      }
      commit("setClientError", err.response ? err.response.data : err);
      throw err;
    });
};

export const getClient = ({ commit }, { id }) => {
  commit("setClientLoading");
  return ApolloClient.query({
    query: GET_CLIENT,
    variables: {
      id: id,
    },
    fetchPolicy: "no-cache",
  })
    .then((res) => {
      commit("setClientSuccess", res.data.client);
      return res.data;
    })
    .catch((res) => {
      console.error(res);
      if (!res.errors) {
        return;
      }
      commit("setClientError", res.errors);
      throw res.errors;
    });
};

export const postClient = ({ commit, state }) => {
  commit("setClientSaveLoading");
  return ApolloClient.mutate({
    mutation: SAVE_CLIENT,
    variables: {
      ...state.client,
      id: 0,
    },
  })
    .then((res) => {
      commit("setPostClientSuccess", res.data.saveClient);
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      commit("setClientError", err.response ? err.response.data : err);
      throw err;
    });
};

export const putClient = ({ commit, state }, app = "bo") => {
  commit("setClientSaveLoading");
  return ApolloClient.mutate({
    mutation: SAVE_CLIENT,
    variables: {
      ...state.client,
      app,
    },
  })
    .then((res) => {
      commit("setPutClientSuccess", res.data.saveClient);
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      commit("setClientError", err.response ? err.response.data : err);
      throw err;
    });
};

export const putClientReport = ({ commit, state }) => {
  commit("setClientSaveLoading");
  return ApolloClient.mutate({
    mutation: REPORT_CLIENT,
    variables: state.client,
  })
    .then((res) => {
      commit("setPutClientSuccess", res.data.reportClient);
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      commit("setClientError", err.response ? err.response.data : err);
      throw err;
    });
};

export const putGenerateQrCode = ({ commit, state }) => {
  commit("setClientSaveLoading");
  return ApolloClient.mutate({
    mutation: GENERATE_CLIENT_QR_CODE,
    variables: state.client,
  })
    .then((res) => {
      commit("setPutClientQrCodeSuccess", res.data.generateClientQrCode);
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      commit("setClientError", err.response ? err.response.data : err);
      throw err;
    });
};

export const postClientContact = ({ commit, state }, { contact }) => {
  commit("setClientSaveLoading");
  return ApolloClient.mutate({
    mutation: SAVE_CLIENT_CONTACT,
    variables: {
      ...contact,
      clientId: state.client.id,
      id: 0,
    },
  })
    .then((res) => {
      let contactList = [
        ...res.data.saveClientContact.familyContact,
        ...res.data.saveClientContact.medicalContact,
        ...res.data.saveClientContact.contactList,
      ];
      for (let i = 0; i < contactList.length; i++) {
        let contact = contactList[i];
        if (
          res.data.saveClientContact.referent &&
          res.data.saveClientContact.referent.id === contact.id
        ) {
          contact.isReferent = true;
          contactList.splice(i, 1, contact);
          break;
        }
      }
      commit("setContactList", res.data.saveClientContact.contactList);
      commit("setMedicalContact", res.data.saveClientContact.medicalContact);
      commit("setFamilyContact", res.data.saveClientContact.familyContact);
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      commit("setClientError", err.response ? err.response.data : err);
      throw err;
    });
};
export const putClientContact = ({ commit, state }, { contact }) => {
  commit("setClientSaveLoading");
  return ApolloClient.mutate({
    mutation: SAVE_CLIENT_CONTACT,
    variables: {
      clientId: state.client.id,
      ...contact,
    },
  })
    .then((res) => {
      const { contactList, familyContact, medicalContact, referent } =
        res.data.saveClientContact;
      const updatedContactList = contactList.map((contact) => ({
        ...contact,
        isReferent: referent && referent.id === contact.id,
      }));
      const updatedMedicalContact = medicalContact.map((contact) => ({
        ...contact,
        isReferent: referent && referent.id === contact.id,
      }));
      const updatedFamilyContact = familyContact.map((contact) => ({
        ...contact,
        isReferent: referent && referent.id === contact.id,
      }));
      commit("setContactList", updatedContactList);
      commit("setMedicalContact", updatedMedicalContact);
      commit("setFamilyContact", updatedFamilyContact);
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      const errorData = err.response ? err.response.data : err;
      commit("setClientError", errorData);
      throw errorData;
    });
};
export const deleteClientContact = ({ commit, state }, { contactId }) => {
  commit("setClientSaveLoading");
  return ApolloClient.mutate({
    mutation: DELETE_CLIENT_CONTACT,
    variables: {
      clientId: state.client.id,
      id: contactId,
    },
  })
    .then((res) => {
      commit("setPutClientSuccess", res.data.deleteClientContact);
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      commit("setClientError", err.response ? err.response.data : err);
      throw err;
    });
};

export const postClientPhoto = async ({ commit, state }) => {
  commit("setPostClientPhoto");
  const data = state.client.photo;
  await postUpload(`${API_URL}/client/picture/${state.client.id}/upload`, data)
    .then((response) => {
      if (!response.ok) {
        if (response.status === 404 || response.status === 401) {
          commit("setPostClientPhotoError", response);
          throw response;
        }
      } else {
        commit("setPostClientPhotoSuccess");
      }
      return response;
    })
    .catch((err) => {
      console.error(err);
      commit("setPostClientPhotoError", err);
      throw err;
    });
};

export const postClientDocument = async ({ commit, state }) => {
  commit("setPostClientDocument");
  const data = state.client.document;
  await postUpload(`${API_URL}/client/document/${state.client.id}/upload`, data)
    .then((response) => {
      if (!response.ok) {
        if (response.status != 200) {
          commit("setPostClientDocumentError", response);
          throw response;
        }
      } else {
        commit("setPostClientDocumentSuccess");
      }
      return response;
    })
    .catch((err) => {
      console.error(err);
      commit("setPostClientDocumentError", err);
      throw err;
    });
};
export const deleteClientDocument = ({ commit }, { id }) => {
  commit("setClientSaveLoading");
  return ApolloClient.mutate({
    mutation: DELETE_CLIENT_DOCUMENT,
    variables: {
      id: id,
    },
  })
    .then((res) => {
      commit("setDeleteClientDocumentSuccess", res.data.deleteClientDocument);
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      commit("setClientError", err.response ? err.response.data : err);
      throw err;
    });
};

export const postAssignUser = ({ commit, state, dispatch }) => {
  commit("setClientSaveLoading");
  return ApolloClient.mutate({
    mutation: ASSIGN_USER,
    variables: {
      assignId: state.client.assignId,
      assignClientIid: state.client.assignClientIid,
      assignUserId: state.client.assignUserId,
      startDate: Moment.parseZone(state.client.startDate).format(), //'YYYY-MM-DD hh:mm:sss'
      endDate: Moment.parseZone(state.client.endDate).format(), //'YYYY-MM-DD hh:mm:sss'
    },
  })
    .then((res) => {
      commit("setPutClientSuccess", res.data.assignUser);
      dispatch("getClient", { id: state.client.id });
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      commit("setClientError", err.response ? err.response.data : err);
      throw err;
    });
};

export const deleteClient = ({ commit }, { id }) => {
  commit("setClientLoading");
  return ApolloClient.mutate({
    mutation: DELETE_CLIENT,
    variables: {
      id: id,
    },
  })
    .then((res) => {
      commit("setDeleteClientSuccess", res.data.deleteClient);
      return res.data;
    })
    .catch((err) => {
      console.error(err);
      commit("setClientError", err.response ? err.response.data : err);
      throw err;
    });
};

export const getCity = async ({ commit },{ name }) => {
  try {
    const response = await getAsApplicationJson(`${API_URL}/gouv/geo/${name}`);
    const data = await response.json();
    commit("setCity", data);
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};


export default {
  getClientSearch,
  getClientList,
  getClient,
  putClient,
  putClientReport,
  postClient,
  postClientPhoto,
  postClientDocument,
  deleteClient,
  deleteClientDocument,
  postAssignUser,
  postClientContact,
  putClientContact,
  deleteClientContact,
  putGenerateQrCode,
  getCity,
};
