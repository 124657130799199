<template>
  <div>
    <div class="id--info">
      <div class="id--info-photo cursor-pointer">
        <div class="id--info-photo--avatar" v-if="profilPicture" :style="{ backgroundImage: `url(${profilPicture ? API_URL + profilPicture.path : ''})` }" @click="profilPreview">
        </div>
        <img v-else class="id--info-photo--avatar" src="@/assets/photo-id.svg" @click="profilPictureModal"/>
      </div>
      <div class="id--info-container">
        <div class="row" v-if="!loading">
          <div class="col-2">
            <div class="id--info-item">
              <img class="id--info-item-img" src="@/assets/icon-filaire-user.svg" />
              <button class="btn id--edit-btn" @click.prevent="editInfo=!editInfo">
                    <img width="18" src="@/assets/icon-plein-edit.svg" />
              </button>
              <div class="id--info-wrap">
                <div style="display: flex; align-items: center;">
                </div>
              </div>
            </div>
          </div>
          <div class="col-5">
            <div class="id--info-item">
              <img width="30" src="@/assets/icon-filaire-home.svg"/>
              <p class="ml-2 information">{{ address }} {{ postalCode }}, {{ city }}</p>
              <button class="btn id--edit-btn" @click.prevent="editAddress=!editAddress">
                <img width="18" src="@/assets/icon-plein-edit.svg"/>
              </button>
            </div>
          </div>
          <div class="col-5">
            <div class="id--info-item">
              <img class="id--info-item-img" src="@/assets/icon-filaire-tel.svg"/>
              <div class="id--info-wrap">
                <p> {{portablePhoneNumber}}<br />{{homePhoneNumber}}</p>
              </div>
              <button class="btn id--edit-btn" @click="editPhone=!editPhone">
                <img width="18" src="@/assets/icon-plein-edit.svg" alt />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="editInfo">
      <div class="col-12">
        <div class="w-50 pr-3 mt-3">
          <form-input
            v-model="ins"
            label="Matricule INS :"
            :login-input="false"
            :disabled="true"
            :max="15"
          />
        </div>
        <div class="w-50 pr-3">
          <form-input
            v-model="oid"
            label="OID :"
            :login-input="true"
            :disabled="true"
            :max="20"
          />
        </div>
      </div>
      <div class="col-6">
        <form-input
          v-model="birthName"
          label="Nom de naissance * :"
          :login-input="false"
          :error="$v.birthName.$error ? 'Le nom de naissance est obligatoire.' : null"
          :disabled="identificationStatus?.label != 'identité provisoire'"
          name="checkControl"
        />
      </div>
      <div class="col-6">
        <form-select
          v-model="sex"
          label="Sexe * :"
          :disabled="identificationStatus?.label != 'identité provisoire'"
          :error="$v.sex.$error ? 'Le sexe est obligatoire.' : null"
          :login-input="false"
        >
          <template v-slot:first>
            <option value="M">MASCULIN</option>
            <option value="F">FEMININ</option>
            <option value="I">INDETERMINE</option>
          </template>
        </form-select>
      </div>
      <div class="col-6">
        <form-input
            v-model="birthFirstnames"
            label="Prénom(s) de naissance :"
            :login-input="false"
            :error="$v.birthFirstnames.$error ? 'Le champ Prénom(s) de naissance est obligatoire.' : null"
            :disabled="identificationStatus?.label != 'identité provisoire'"
            name="checkControl"
            @input="labelConstraint"
          />
      </div>
      <div class="col-6">
        <form-input
          v-model="lastName"
          label="Nom utilisé :"
          :login-input="false"
          :disabled="false"
          name="checkControl"
        />
      </div>
      <div class="col-6">
        <FormDatePicker
        v-model="birthDate"
        label="Date de naissance *"
        :value="birthDate"
        :error="$v.birthDate.$error ? 'Le date de naissance est obligatoire.' : null"
        :disabled="identificationStatus?.label != 'identité provisoire'"
        :login-input="true">
        </FormDatePicker>
      </div>
      <div class="col-6">
        <form-input
          v-model="birthFirstname"
          label="1er Prénom de naissance *"
          :login-input="false"
          :error="errorMessages"
          :disabled="false"
          name="checkControl"
        />
      </div>
      <div class="col-6">
        <SelectCountry
          :label="'Pays de naissance :'"
          :value="birthCountry"
          :disabled="identificationStatus?.label != 'identité provisoire'"
          @setValue="setBirthCountry"
        />
      </div>
      <div class="col-6">
        <label for="firstName">Prénom utilisé :</label>
        <input class="form-control" v-model="firstName" id="firstName" name="checkControl"/>
      </div>
      <div class="col-6">
        <City :selectedCity="client.birthCity" @codeInsee="setCity" forCity="true" :disabled="isFrenchField() || identificationStatus?.label != 'identité provisoire'"/>
      </div>
      <div class="col-6">
        <label for>Numéro de sécurité sociale * :</label>
        <input :disabled="identificationStatus?.label != 'identité provisoire'" class="form-control" v-model="socialSecurityNumber"/>
        <small v-if="$v.socialSecurityNumber.$error" class="text-danger">Le Numéro de sécurité sociale est obligatoire et doit être au format : x xx xx xx xxx xxx xx.</small>
      </div>
      <div class="col-6">
        <label for="codeInsee">Code lieu de naissance (Code INSEE) * :</label>
        <input class="form-control" maxlength="5" v-model="codeInsee" id="codeInsee" name="codeInsee" :disabled="isFrenchField() || identificationStatus?.label != 'identité provisoire'"/>
      </div>
      <div class="col-6">
        <SelectCountry
          :label="'Nationalité :'"
          :value="nationality"
          :error="$v.nationality.$error"
          :disabled="identificationStatus?.label != 'identité provisoire'"
          @setValue="setCountry"
        />
        <small v-if="nationalityError" class="text-danger">La nationalité est obligatoire.</small>
      </div>
      <div class="col-12 text-center">
        <b-button class="mt-2" variant="btn btn-outline-primary" @click.prevent="updateInfo">
          Sauvegarder
        </b-button>
      </div>
    </div>
    <Detail
      :ins='ins'
      :oid='oid'
      :birthName='birthName'
      :birthFirstnames='birthFirstnames'
      :birthFirstname='birthFirstname'
      :birthCountry='birthCountry'
      :birthCity='birthCity'
      :codeInsee='codeInsee'
      :firstname='firstName'
      :lastname='lastName'
      :sex='sex'
      :socialSecurityNumber='socialSecurityNumber'
      :nationality='nationality'
      :birthDateFR='birthDateFR'
      :identificationStatus='identificationStatus?.label'
      :age="this.age"
      v-else
    />
    <div class="col-12 text-center" v-if="isFrench()">
        <b-button variant="btn mt-4 mb-4 btn-primary mt-2" @click.prevent="insiModalView">
          Téléservice INSI
          <img width="24" height="24" class="ml-3" src="../../../assets/insi.png" />
        </b-button>
      </div>
    <div class="row justify-content-between">
      <div v-if="editPhone" class="col-5">
      <div>
        <h3><img width="30" src="@/assets/icon-filaire-tel.svg" class="mr-3" />Coordonnées</h3>
        <div class="row">
          <div class="col-12 p-0">
            <form-input
              v-model="email"
              label="Adresse email *"
              :login-input="false"
              :disabled="false"
            />
          </div>
          <div class="col-12 p-0">
            <form-input
              v-model="portablePhoneNumber"
              label="Téléphone Portable *"
              :login-input="false"
              :disabled="false"
            />
          </div>
          <div class="col-12 p-0">
            <form-input
              v-model="homePhoneNumber"
              label="Téléphone fixe"
              :login-input="false"
              :disabled="false"
            />
          </div>
          <div class="col-12 text-center">
            <b-button class="mt-2"
                      variant="btn mt-4 mb-4 btn-outline-primary"
                      @click.prevent="updatePhone">
              Sauvegarder
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="editAddress" class="col-5">
      <div>
        <h3><img width="30" src="@/assets/icon-filaire-home.svg" class="mr-3"/>Adresse</h3>
        <div class="row">
          <div class="col-12 p-0">
            <form-input
              v-model="address"
              label="Adresse postale *"
              :login-input="false"
              :disabled="false"
            />
          </div>
          <div class="col-12 p-0">
            <form-input
              v-model="postalCode"
              label="Code postal"
              :login-input="false"
              :disabled="false"
            />
            <small v-if="!$v.postalCode.integer && $v.postalCode.$error" class="text-danger">Format du code postal incorrect.</small>
          </div>
          <div class="col-12 p-0">
            <form-input
              v-model="city"
              label="Ville"
              :login-input="false"
              :disabled="false"
            />
          </div>
          <div class="col-12 text-center">
            <b-button class="mt-2"
                      variant="btn mt-4 mb-4 btn-outline-primary"
                      @click.prevent="updateAddress">
              Sauvegarder
            </b-button>
          </div>
        </div>
      </div>
    </div>
    </div>
    <Identification
      label="identification"
      :loading="false"
      :dataList="documents"
      @remove="removeDocument"
      @modalActiveIdentity="modalActiveIdentity"
    />

    <div v-if="accessDocument" class="id--section">
      <div class="id--section-title">
        <h1>DOCUMENTS</h1>
      </div>
      <div class="id--section-content">
          <eval-grid-document
              id="Liste des documents"
              label="document"
              :loading="false"
              :dataList="documents"
              @remove="removeDocument"
              @modalActive="modaleActive"/>
      </div>
    </div>

  <div class="id--section">
    <div class="id--section-title">
      <h1>Éléments d'accès au logements pour les intervenants</h1>
    </div>
    <div class="id--section-content">
      <div class="row mb-3">
        <div class="col-12">
          <label for>Description des modalités d'accès depuis la rue</label>
          <textarea v-model="housingAccess" class="form-control" @change="updateClientInfo"></textarea>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <label for>Modalités d'accès au logement</label>
          <textarea v-model="digicode" class="form-control" @change="updateClientInfo"></textarea>
        </div>
      </div>
    </div>
  </div>

  <div v-if="accessPhoto" class="id--section">
    <div class="id--section-title">
      <h1>Repérage photo</h1>
    </div>
    <div class="id--section-content">
      <eval-grid-document
          id="Liste des Lieux"
          label="lieux"
          :loading="false"
          :dataList="lieuxDocuments"
          @remove="removeDocument"
          @modalActive="modaleActive('acces')"/>
    </div>
  </div>

  <div class="id--section">
    <div class="id--section-content">
      <div class="row mb-3">
        <div class="col-12">
          <label for>Commentaires de l’évaluateur sur les informations du bénéficiaire</label>
          <textarea v-model="clientComment" class="form-control" @change="changeCommentaryValue()"></textarea>
        </div>
      </div>
    </div>
  </div>
    <custom-modal :active="insiModal" @close="insiModalView">
      <div class="d-flex mb-3">
        <img width="42" height="42" class="mr-3" src="../../../assets/insi.png" />
        <h3 class="id-modal--title-insi">Téléservice INSI</h3>
      </div>
      <p>Veuillez saisir les traits d’identité du patient</p>
      <p>Le nom, le prénom et le lieu ne doivent contenir que des caractères majuscules, pas d’accent, ni de cédille. <br/>Seuls les espaces, les apostrophes et les tirets (non doublonnés) sont acceptés.</p>
      <div>
        <div class="row">
          <div class="col-4 mt-4">
            <label for="birthName">Nom de naissance</label>
            <input class="form-control" id="birthName" name="birthName" disabled v-model="birthName"/>
          </div>
          <div class="col-4 mt-4">
            <label for="firstName">Prénom(s)</label>
            <input class="form-control" id="firstName" name="firstName" disabled v-model="firstName"/>
          </div>
        </div>
        <div class="row">
          <div class="col-4 mt-4">
            <label for="birthDate">Date de naissance</label>
            <input class="form-control" id="birthDate" name="birthDate" disabled v-model="birthDateFR"/>
          </div>
          <div class="col-4 mt-4">
            <label for="codeInsee">Commune de naissance</label>
            <input class="form-control" id="codeInsee" name="codeInsee" disabled v-model="codeInsee"/>
          </div>
          <div class="col-4 mt-4">
            <label for="birthName">Sexe</label>
            <input class="form-control" id="sex" name="sex" disabled v-model="sexIsni"/>
          </div>
        </div>
        <div class="text-right">
          <b-button :class="{'disabled-btn': (
            codeInsi != null
            || codeInsee == ''
            || sex == ''
            || birthDateFR == ''
            || firstName == ''
            || birthName == ''
            )}"
            class="mt-4"
            :disabled="(codeInsi != null
            || codeInsee == ''
            || sex == ''
            || birthDateFR == ''
            || firstName == ''
            || birthName == ''
            )" variant="btn col-3 btn-primary w-auto"
            @click.prevent="getInsiData">
            Obtenir L'INS
          </b-button>
        </div>
        <VerificationIns
          v-if="codeInsee == ''
            || codeInsee == null
            || sex == ''
            || sex == null
            || birthDateFR == ''
            || birthDateFR == null
            || firstName == ''
            || firstName == null
            || birthName == ''
            || birthName == null"
          res='ERROR'
          @close="closeModal"
         />
        <h3 v-if="codeInsi != null" class="col-12 mt-5 font-weight-bold">Résultat de recherche</h3>
        <Result v-if="codeInsi == '00'"
          :insi="getDataInsi"
          :id="client.id"
          :close="insiModalView"
        />
        <ResultInfo
          v-if="codeInsi == '01' || codeInsi == '02'"
          :res='codeInsi'
         />
      </div>
    </custom-modal>

    <custom-modal :active="modalActive" @close="modalActive = false;isSubmitDocument=false;">
      <div class="id-modal">
        <div class="id-modal--header">
          <img src="@/assets/icon-plein-add.svg" alt />
          <h1 class="id-modal--title">AJOUTER UN DOCUMENT</h1>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group eval-form eval-form--select">
              <form-select v-model="modalFormType"
                           :options="documentModalListType"
                           label="Type de document"
                           :disabled="false"
                           :error="!modalFormType && isSubmitDocument ? 'Le type est obligatoire.' : null"
                           :login-input="true"
                           :show-first-option="false"
                           @change="handleSelectChange"
              >
              </form-select>
            </div>
            <div class="form-group eval-form eval-form--textarea">
              <form-textarea v-model="modalDescription" label="Description" :rows="3"
                             :value="modalDescription" :login-input="true" :disabled="false"/>
            </div>
          </div>
          <div class="col-6">
            <h5 class="id-modal--subtitle">PHOTO</h5>
            <document-simple
                title=""
                v-if="modalActive"
                v-model="document"
                imageUrl=""
                mention="100px*100px min"
                @input="filesDocument"/>
            <p class="text-danger" v-if="!document && isSubmitDocument">* Le document est obligatoire.</p>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-5">
          <button class="btn btn-primary" @click.prevent="submitUploadDocument">Valider</button>
        </div>
      </div>
    </custom-modal>


  <AddIdentityDocumentModal
    :active="modalIdentity"
    @close="modalIdentity = false"
  />
  <custom-modal v-if="accessPhoto" :active="profilModal" @close="profilModal = false;isSubmitDocument=false;">
    <div class="id-modal">
      <div class="id-modal--header">
        <img src="@/assets/icon-plein-add.svg" alt />
        <h1 class="id-modal--title">AJOUTER UNE PHOTO DU CLIENT</h1>
      </div>
      <div class="row">
        <div class="col-6">
          <h5 class="id-modal--subtitle">PHOTO</h5>
          <document-simple
              title=""
              v-model="document"
              v-if="profilModal"
              imageUrl=""
              mention="100px*100px min"
              @input="profilPictureDocument"/>
          <p class="text-danger" v-if="!document && isSubmitDocument">* Le document est obligatoire.</p>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-5">
        <button class="btn btn-primary"  :disabled="isSubmitDocument" @click.prevent="submitUploadDocument('photo')">Valider</button>
      </div>
    </div>
  </custom-modal>
  <custom-modal :active="profilPreviewModal" @close="profilPreviewModal = false">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <img :src="previewURL" width="100%">
    </div>
    <div>
      <b-button class="mt-2" variant="btn col-3 btn-outline-danger" v-if="previewDocumentId" @click.prevent="removeDocument(previewDocumentId)">Supprimer</b-button>
      <b-button class="mt-2" variant="btn col-3 btn-outline-secondary" @click.prevent="profilPreviewModal = false">Annuler</b-button>
    </div>
  </custom-modal>
</div>
</template>
<script>
  import Moment from "moment";
  import DocumentSimple from "@/components/Form/DocumentSimple";
  import Detail from "@/views/Evaluation/Identity/Detail";
  import ResultInfo from "@/views/Evaluation/Identity/ResultInfo";
  import Result from "@/views/Evaluation/Identity/Result";
  import EvalGridDocument from "@/components/Eval/EvalGridDocument";
  import Identification from "@/components/Eval/Identification";
  import SelectCountry from "@/components/Form/SelectCountry";
  import AddIdentityDocumentModal from "@/components/Eval/AddIdentityDocumentModal.vue";
  import City from "@/components/Form/City";
  import {mapActions, mapMutations, mapState} from "vuex";
  import {API_URL} from '@/components/Util/EnvVariable';
  import {email, integer, required} from 'vuelidate/lib/validators';
  import {formatFirstCharToUpperCase, formatSecuritySocialNumber} from "@/components/Util/UtilFunction";
  import FormDatePicker from "@/components/Form/DatePicker.vue";
  import VerificationIns from './VerificationIns.vue';


  export default {
    data() {
      return {
        API_URL: API_URL,
        document: null,
        isSubmitDocument: false,
        editInfo: false,
        editAddress: false,
        editPhone: false,
        modalActive: false,
        modalIdentity: false,
        modalFormType: null,
        modalDescription: null,
        documentMainType: null,
        documentModalListType: [],
        profilModal: false,
        insiModal: false,
        profilPreviewModal: false,
        previewURL: null,
        previewDocumentId: null,
        birthDateDay: null,
        birthDateMonth: null,
        birthDateYear: null,
        birthDateError: false,
        nationalityError: false,
        birthFirstnameError: false
      };
    },
    components:{
      FormDatePicker,
      DocumentSimple,
      EvalGridDocument,
      Detail,
      ResultInfo,
      Identification,
      Result,
      SelectCountry,
      AddIdentityDocumentModal,
      City,
      VerificationIns
    },
    validations() {
        return {
          sex: {
            required,
          },
          birthName: {
            isNotEmpty(value) {
              return value.length > 0;
            },
            required: true
          },
          birthFirstnames: {
            required
          },
          birthFirstname: {
            isSameFirstName() {
              return this.birthFirstnames.split(' ')[0] == this.birthFirstname;
            },
            isNotEmpty(value) {
              return value.length > 0;
            },
            required: true
          },
          email: {
            email,
            required: false
          },
          postalCode: {
            integer,
            required: false
          },
          nationality: {
            required,
          },
          codeInsee: {
            isNotEmpty(value) {
              return value.length > 0;
            },
            required: this.client.birthCountry === 'France' ? true : false,
          },
          birthDate: {
            required,
          },
          socialSecurityNumber: {
            isSocialNumber(value) {
              return value.length === 21;
            },
            required
          }
        };
    },
    methods:{
      ...mapMutations('Client', ['setClientProperty', 'deleteClientDocumentSuccess']),
      ...mapActions('Client', ['putClient', 'getClient', 'postClientDocument', 'deleteClientDocument']),
      ...mapActions('Evaluation', ['putEvaluation']),
      ...mapActions('Insi', ['getInsi']),
      modaleActive(type) {
        this.document = null;
        this.documentModalListType = type === 'document' ? this.typesDocument : this.accesTypeList;
        this.modalActive = true;
        this.modalFormType = null;
        this.modalDescription = null;
        this.documentMainType = type === 'document' ? 'documents' : 'lieux';
      },
      handleSelectChange(){
        this.isSubmitDocument=false;
      },
      modalActiveIdentity() {
        this.modalIdentity = true;
      },
      closeModal() {
        window.location.reload();
      },
      labelConstraint(event) {
        let val = event
          .toUpperCase()
          .replace(/[^A-Z'\-\s]/g, '')
          .replace(/(-|\s|')\1+/g, '$1')
          .substring(0, 100);

        this.birthFirstname = val.split(' ')[0];
      },
      setCity(value) {
        this.codeInsee = value.code;
        this.birthCity = value.nom;
      },
      filesDocument() {
        let formData = new FormData();
        formData.append('document', this.document);
        this.setClientProperty({name:"document", value: formData});
      },
      submitUploadDocument(fileType = "") {

        if(this.isSubmitDocument) return;
        this.isSubmitDocument = true;
        if (!this.document || (!this.modalFormType && this.documentMainType !== "profil" )) {
          return;
        }
        let formData = new FormData();
        formData.append('document', this.document);
        formData.append('main_type', this.documentMainType);
        formData.append('type', this.modalFormType ? this.modalFormType : "");
        formData.append('description', this.modalDescription ? this.modalDescription : false);
        formData.append('fileType', fileType);
        formData.append('userId', this.connectedUser.id);
        this.setClientProperty({name: "document", value: formData});
        this.postClientDocument().then((data) => {
          this.getClient({id: this.client.id});
          this.profilModal = false;
          this.modalActive = false;
          this.isSubmitDocument = false;
          this.$notify.success("Document sauvegardé !");
          return data;
        }).catch((err) => {
          console.error(err);
          this.$notify.error("Erreur de sauvegarde du document.");
          throw err;
        });
      },
      isFrench() {
        return this.birthCountry == "France" ? true : false;
      },
      isFrenchField() {
        return this.birthCountry == "France" ? false : true;
      },
      setCountry(value) {
        this.nationality = value;
      },
      updatePhone(){
        this.updateInfoApi()
        this.editPhone = !this.editPhone
      },
      setBirthCountry(value) {
        if (value === "France") {
          this.birthCountry = value;
        } else {
          this.birthCountry = value;
          this.birthCity = '';
          this.codeInsee = '';
        }
      },
      updateAddress(){
        if(this.$v.postalCode.$invalid){
          this.$v.postalCode.$touch();
          return;
        }
        this.updateInfoApi()
        this.editAddress = !this.editAddress
      },
      updateInfo() {
        let validateError = false;
        if(this.$v.birthDate.$invalid){
          this.$v.birthDate.$touch();
          validateError = true;
        }
        if(this.$v.socialSecurityNumber.$invalid){
          this.$v.socialSecurityNumber.$touch();
          validateError = true;
        }

        if(!this.$v.birthName.isNotEmpty) {
          this.$v.birthName.$touch();
          validateError = true;
        }

        if(!this.$v.birthFirstname.isNotEmpty){
          this.$v.birthFirstname.$touch();
          validateError = true;
        }

        if(!this.$v.birthFirstname.isSameFirstName){
          this.$v.birthFirstname.$touch();
          validateError = true;
        }

        if(!this.$v.codeInsee.isNotEmpty && this.client.birthCountry === 'France'){
          this.$v.codeInsee.$touch();
          validateError = true;
        }

        if (this.nationality == '') {
          this.nationalityError = true;
          validateError = true;
        }

        if (validateError) {
          return
        }

        this.updateInfoApi()
        this.editInfo = !this.editInfo
      },
      updateInfoApi(){
        this.setClientProperty({
          name: "age",
          value: this.age
        });
        this.updateClientInfo();
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      },
      updateClientInfo() {
        this.initNullValue();
        this.setClientProperty({
          name: "interfaceSyncrhonisation",
          value: true
        });
        let momentDate = Moment(this.birthDate);
        momentDate.add(10, 'hours');
        let date = momentDate.format('DD-MM-YYYY');
        this.birthDate = date;
        this.putClient('evaluation').then((res) => {
          this.$notify.success('L\'information a été sauvegardée');
          return res;
        }).catch((err) => {
          this.$notify.error("Erreur de sauvegarde, vérifiez les informations saisies")
          throw err;
        });
      },
      initNullValue() {
        this.setClientProperty({
          name: "protectiveMeasure",
          value: ''
        });
        this.setClientProperty({
          name: "csp",
          value: ''
        });
      },
      removeDocument(documentId) {
        this.deleteClientDocument({id: documentId}).then((res) => {
          this.$bvModal.hide('preview_document_document');
          this.$bvModal.hide('preview_document_lieux');
          this.$bvModal.hide('preview_document');
          this.profilPreviewModal = false;
          this.$notify.success('Document supprimé.');
          return res;
        }).catch((err) => {
          this.$notify.error("Failed.")
          throw err;
        });
      },
      profilPictureModal() {
        this.document = null;
        this.profilModal = true;
        this.documentMainType = "profil";
      },
      insiModalView() {
        this.insiModal = !this.insiModal;
      },
      getInsiData() {
        try {
          this.getInsi({id: this.evaluation.clientId});
        } catch (err) {
          console.log(err)
        }
      },
      profilPreview() {
        this.profilPreviewModal = true;
        this.previewURL = this.profilPicture ? API_URL + this.profilPicture.path: null;
        this.previewDocumentId = this.profilPicture ?  this.profilPicture.id: null;
      },
      profilPictureDocument() {
        let formData = new FormData();
        formData.append('document', this.document);
        this.setClientProperty({name:"document", value: formData});
      },
      initBirthday(value){
        let momentDate = Moment(value);
        if (!momentDate.isValid()) {
          return;
        }
        let date = momentDate.format('DD-MM-YYYY');
        let dateSplit = date.split('-');
        this.birthDateDay = dateSplit[0];
        this.birthDateMonth = dateSplit[1];
        this.birthDateYear = dateSplit[2];
      },
      changeCommentaryValue() {
        this.putEvaluation();
      }
    },
    computed: {
      ...mapState('Client', ['client', 'typesDocument', 'loading', 'accesTypeList']),
      ...mapState("Evaluation", ["evaluation"]),
      ...mapState('Insi', ['code', 'insi']),
      ...mapState('User', ['connectedUser']),
      age() {
        if(!this.birthDate || this.birthDate === ''){
          return 0;
        }
        return Moment().diff(Moment(this.birthDate), 'years');
      },
      accessPhoto: {
        get() {
          let storedPermissions = sessionStorage.getItem('permissions');
          let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

          return permissionsArray.includes('CAN_ADD_PHOTO');
        }
      },
      accessDocument: {
        get() {
          let storedPermissions = sessionStorage.getItem('permissions');
          let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

          return permissionsArray.includes('CAN_ADD_DOCUMENT');
        }
      },
      documents() {
        return this.client.documents ? this.client.documents.filter(item => item.mainType == 'documents') : []
      },
      lieuxDocuments() {
        return this.client.documents ? this.client.documents.filter(item => item.mainType == 'lieux') : []
      },
      profilPicture() {
        const docs = this.client.documents ? this.client.documents.filter(item => item.mainType == 'profil') : []
        if(docs.length > 0) {
          return docs[0];
        }
        return null;
      },
      errorMessages() {
        if (this.$v.birthFirstname.$error) {
          if (!this.$v.birthFirstname.required) {
            return 'Le 1er prénom de naissance est obligatoire.';
          } else if (!this.$v.birthFirstname.isSameFirstName) {
            return 'Le 1er prénom de naissance ne correspond pas au 1er prénom de Prénom(s) de naissance.';
          } else if (!this.$v.birthFirstname.isNotEmpty) {
            return 'Le 1er prénom de naissance ne correspond pas au 1er prénom de Prénom(s) de naissance.';
          }
        }
        return null
      },
      id: {
        get() {
          return this.client.id
        },
      },
      sexIsni: {
        get() {
          var sex = "INDETERMINE";

          if (this.client.sex == "M") {
            sex = "HOMME";
          }

          if (this.client.sex == "F") {
            sex = "FEMME";
          }

          return sex;
        },
      },
      codeInsi: {
        get() {
          return this.code;
        }
      },
      getDataInsi: {
        get() {
          return this.insi;
        }
      },
      lastName: {
        get() {
          return this.client.lastname;
        },
        set(value) {
          this.setClientProperty({
            name: "lastname",
            value: value
          });
        }
      },
      firstName: {
        get() {
          return this.client.firstname;
        },
        set(value) {
          this.setClientProperty({
            name: "firstname",
            value: value
          });
        }
      },
      birthDate: {
        get() {
          return this.client.birthDate ? this.client.birthDate : null
        },
        set(value) {
          value.setHours(3)
          this.setClientProperty({
            name: "birthDate",
            value: value
          });
        }
      },
      birthDateFR: {
        get() {
          return this.client.birthDate ? Moment(this.client.birthDate).format('DD/MM/YYYY') : null;
        }
      },
      socialSecurityNumber: {
        get() {
          return formatSecuritySocialNumber(this.client.socialSecurityNumber);
        },
        set(value) {
          value = formatSecuritySocialNumber(value);
          this.setClientProperty({
            name: "socialSecurityNumber",
            value: value
          });
        }
      },
      portablePhoneNumber: {
        get() {
          return this.client.portablePhoneNumber;
        },
        set(value) {
          this.setClientProperty({
            name: "portablePhoneNumber",
            value: value
          });
        }
      },
      homePhoneNumber: {
        get() {
          return this.client.homePhoneNumber;
        },
        set(value) {
          this.setClientProperty({
            name: "homePhoneNumber",
            value: value
          });
        }
      },
      address: {
        get() {
          return this.client.address;
        },
        set(value) {
          this.setClientProperty({
            name: "address",
            value: value
          });
        }
      },
      city: {
        get() {
          return this.client.city;
        },
        set(value) {
          this.setClientProperty({
            name: "city",
            value: formatFirstCharToUpperCase(value)
          });
        }
      },
      postalCode: {
        get() {
          return this.client.postalCode;
        },
        set(value) {
          this.setClientProperty({
            name: "postalCode",
            value: value
          });
        }
      },
      birthCity: {
        get() {
          return this.client.birthCity;
        },
        set(value) {
          this.setClientProperty({
            name: "birthCity",
            value: formatFirstCharToUpperCase(value)
          });
        }
      },
      nationality: {
        get() {
          return this.client.nationality;
        },
        set(value) {
          this.setClientProperty({
            name: "nationality",
            value: value
          });
        }
      },
      email: {
        get() {
          return this.client.email;
        },
        set(value) {
          this.setClientProperty({
            name: "email",
            value: value
          });
        }
      },
      housingAccess: {
        get() {
          return this.client.housingAccess;
        },
        set(value) {
          this.setClientProperty({
            name: "housingAccess",
            value: value
          });
        }
      },
      digicode: {
        get() {
          return this.client.digicode;
        },
        set(value) {
          this.setClientProperty({
            name: "digicode",
            value: value
          });
        }
      },
      ins: {
        get() {
          return this.client.matriculeIns
        },
        set(value) {
          this.setClientProperty({
            name: 'ins',
            value: value
          });
        }
      },
      oid: {
        get() {
          return this.client.oid
        },
        set(value) {
          this.setClientProperty({
            name: 'oid',
            value: value
          });
        }
      },
      codeInsee: {
        get() {
          return this.client.codeInsee
        },
        set(value) {
          this.setClientProperty({
            name: 'codeInsee',
            value: value
          });
        }
      },
      sex: {
        get() {
          return this.client.sex;
        },
        set(value) {
          this.setClientProperty({
            name: 'sex',
            value: value
          });
        }
      },
      birthCountry: {
        get() {
          return this.client.birthCountry
        },
        set(value) {
          this.setClientProperty({
            name: 'birthCountry',
            value: value
          });
        }
      },
      birthFirstnames: {
        get() {
          return this.client.birthFirstnames
        },
        set(value) {
          this.setClientProperty({
            name: 'birthFirstnames',
            value: value
          });
        }
      },
      birthFirstname: {
        get() {
          return this.client.birthFirstname
        },
        set(value) {
          this.setClientProperty({
            name: 'birthFirstname',
            value: value
          });
        }
      },
      birthName: {
        get() {
          return this.client.birthName
        },
        set(value) {
          this.setClientProperty({
            name: 'birthName',
            value: value
          });
        }
      },
      identificationStatus: {
        get() {
          return this.client.identificationStatus;
        }
      },
      clientComment: {
        get() {
          return this.evaluation.evaluatorComments ? this.evaluation.evaluatorComments.clientComment : "";
        },
        set(value) {
          this.evaluation.evaluatorComments.clientComment = value;
        }
      },
    },
    watch: {
      birthDate(value) {
        this.initBirthday(value);
      },
      modalFormType(newValue)
      {
        if(newValue)
        {
          this.isSubmitDocument=false;
        }

      }
    },
    mounted() {
      this.initBirthday(this.birthDate);
    }
  };
</script>
<style scoped>
  input.input-split-date{
    max-width: 60px;
    margin-left: 10px;
    margin-right: 10px;
  }
  input.input-split-date:nth-child(1){
    margin-left: 0;
  }
  input.input-split-date-year {
    max-width: 90px;
    margin-left: 10px;
    margin-right: 10px;
  }
  div.display-flex{
    display: flex;
  }
  .disabled-btn {
    background-color: #ffffff !important;
    border-color: #929090 !important;
    color: #929090 !important;
  }
</style>