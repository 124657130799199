<template>
    <div class="container cgu">
      <h1 class="cgu__title">Conditions Générales d'Utilisation</h1>
      <h3 class="cgu__subtitle">1. Objet des CGU</h3>
      <p>
        Les présentes CGU ont pour objet de définir les conditions d'accès et d'utilisation de l'application web Bien Vieillir IDF, utilisée pour l'évaluation des personnes âgées et/ou en situation de handicap dans le cadre de leur prise en charge.
      </p>
      <h3 class="cgu__subtitle">2. Accès à l'Application</h3>
      <p>
        L'accès à l'Application est réservé qu'aux utilisateurs autorisés après identification. Chaque utilisateur est responsable de la confidentialité de ses identifiants d'accès. Toute utilisation de l'Application via ses identifiants est présumée faite par l'utilisateur.
      </p>
      <p>
        Les Utilisateurs devront s'identifier pour se connecter à l'Application au moyen des Informations de connexion qui sont personnelles et confidentielles. Elles ne peuvent être modifiées que sur demande du Client ou à l'initiative de Bien Vieillir IDF, sous réserve d'en informer préalablement le Client. Le Client s'engage à mettre tout en œuvre pour conserver secrètes les informations de connexion le concernant et à ne pas les divulguer sous quelque forme que ce soit.
      </p>
      <p>
        En tout état de cause, l'utilisation des informations de connexion constitue une preuve d'utilisation des ressources mises à disposition par Bien Vieillir IDF, le Client étant seul responsable de la gestion et de la sécurité de ces informations. Bien Vieillir IDF ne saurait être tenu responsable des dommages causés par l'utilisation des informations de connexion par les Utilisateurs, y compris par des personnes non autorisées par le Client à accéder à l'Application.
      </p>
      <p>
        Le Client s'engage à informer immédiatement Bien Vieillir IDF de tout incident de sécurité et/ou de toute utilisation frauduleuse constatée ou suspectée. En cas de perte ou de vol d'informations de connexion, l'Utilisateur doit contacter Bien Vieillir IDF, qui lui indiquera la procédure sécurisée applicable pour récupérer ses identifiants. Bien Vieillir IDF pourra, par mesure de sécurité, suspendre temporairement l'accès à l'Application pendant cette procédure.
      </p>
      <p>
        <b> Connexion avec le service Pro Santé Connect :</b>
      </p>
      <p>
        L'Application permet aux utilisateurs de se connecter via <b>Pro Santé Connect</b>, un téléservice mis en œuvre par l'Agence du Numérique en Santé (ANS) qui simplifie l'identification électronique des professionnels de santé. Les utilisateurs peuvent accéder à l'Application en utilisant leur carte CPS avec un lecteur de cartes compatible et les composants requis.
      </p>
    <p>
      Les utilisateurs sont tenus de consulter Conditions Générales d'Utilisation de Pro Santé Connect avant d'utiliser ce mode de connexion : <a style="color: #1e48d1" href="https://industriels.esante.gouv.fr/produits-et-services/pro-sante-connect/conditions-generales-d-utilisation-pro-sante-connect">https://industriels.esante.gouv.fr/produits-et-services/pro-sante-connect/conditions-generales-d-utilisation-pro-sante-connect</a>
    </p>
    <h3 class="cgu__subtitle">3. Collecte des données</h3>
    <p>
      Les utilisateurs sont amenés à fournir des informations personnelles et administratives des patients, notamment leur identité, les informations de santé, la grille AGGIR, ainsi que des documents tels que la carte vitale, carte nationale d'identité (CNI), RIB, et d’autres pièces administratives.
    </p>
      <p>
        Nous vous invitons à vous reporter à notre <b>Politique de protection des données personnelles</b>, disponible sur notre site <a style="color: #1e48d1" href="/#/rgpd">ici</a>.
      </p>
    <h3 class="cgu__subtitle">4. Responsabilité des utilisateurs</h3>
    <p>
      Les utilisateurs de l'application s'engagent à respecter les exigences du Règlement Général sur la Protection des Données (RGPD) lors de la collecte et du traitement des données personnelles des patients ou des clients.
    </p>
      <p>
        Ils sont tenus de recueillir le consentement libre, éclairé et explicite de chaque patient avant d'insérer ou de traiter leurs données dans l'application.
      </p>
    <h3 class="cgu__subtitle">5. Propriété intellectuelle</h3>
    <p>
      Le contenu de l'application (textes, images, graphiques, logo, interfaces, bases de données, etc.) est protégé par les lois relatives à la propriété intellectuelle, notamment l’article L.122-4 du Code de la Propriété Intellectuelle.
    </p>
      <p>
        Toute reproduction, représentation, modification, publication ou adaptation des éléments de l'application, sans autorisation préalable de Bien Vieillir IDF, est interdite.
      </p>
      <p>
        L'utilisation des éléments de l'application est exclusivement réservée à des fins professionnelles et non commerciales. Par ailleurs, Bien Vieillir IDF se réserve le droit d'utiliser des données anonymisées à des fins statistiques ou scientifiques.
      </p>
    <h3 class="cgu__subtitle">6. Traitement des données</h3>
    <p>
      Les données collectées via l'application sont traitées conformément aux exigences du RGPD. Les utilisateurs disposent d’un droit d'accès, de rectification, de suppression, de limitation, et de portabilité de leurs données conformément à la législation en vigueur.
    </p>
      <p>
        Les documents personnels (carte vitale, RIB, CNI) peuvent être insérés. Les documents sont stockés de manière sécurisés et cryptés dans l’application pour garantir une protection optimale.
      </p>
      <p>
        Les utilisateurs sont tenus de consulter la <b>Politique de protection des données personnelles</b> pour obtenir plus de détails sur la gestion de leurs informations personnelles.
      </p>
    <h3 class="cgu__subtitle">7. Cookies</h3>
    <p>
      L'utilisation des cookies est strictement limitée à la gestion des sessions utilisateurs et au téléchargement des fichiers PDF et à la connexion à l'application web pour authentifier les utilisateurs et maintenir leur session active.
    </p>
      <p>
        Aucun cookie n'est utilisé à des fins publicitaires ou de profilage.
      </p>
      <p>
        Les utilisateurs ont la possibilité de configurer leur navigateur pour bloquer ou supprimer les cookies, bien que cela puisse altérer certaines fonctionnalités de l'application.
      </p>
    <h3 class="cgu__subtitle">8. Responsabilité</h3>
    <p>
      Bien Vieillir IDF s'engage à mettre en œuvre tous les moyens nécessaires pour assurer un accès continu à l'application, mais ne peut garantir une disponibilité ininterrompue.
    </p>
      <p>
        Bien Vieillir IDF ne peut être tenue responsable des dommages directs ou indirects (tels que pertes de données, interruption d'activité, etc.) résultant de l'utilisation de l'application.
      </p>
      <p>
        Les utilisateurs sont seuls responsables de la sécurité de leurs équipements et de leur connexion internet lors de l'utilisation de l'application.
      </p>
    <h3 class="cgu__subtitle">9. Modification des CGU</h3>
    <p>
      Les CGU peuvent être modifiées à tout moment par Bien Vieillir IDF pour s'adapter aux évolutions législatives, techniques ou des services.
    </p>
      <p>
        Les utilisateurs seront informés de ces modifications via l'application. La poursuite de l'utilisation de l'application après la mise à jour des CGU vaut acceptation des nouvelles conditions.
      </p>
      <h3 class="cgu__subtitle">10. Résiliation et suspension de compte</h3>
      <p>
        Bien Vieillir IDF se réserve le droit de suspendre ou de résilier un compte utilisateur en cas de non-respect des présentes CGU, de comportement inapproprié ou de fraude.
      </p>
      <p>
        L’utilisateur peut faire la demande de suppression de son compte et de restitution de ses données. Bien Vieillir IDF peut toutefois conserver des données anonymisées des utilisateurs à des fins statistiques ou scientifiques.
      </p>
      <p>
        En cas de suspension ou de résiliation du compte, les données personnelles de l'utilisateur seront traitées conformément à la Politique de protection des données personnelles.
      </p>
      <h3 class="cgu__subtitle">11. Loi applicable et juridiction compétente</h3>
      <p>
        Les présentes CGU sont régies par la loi française.
      </p>
      <p>
        En cas de litige lié à l'interprétation ou à l'exécution des présentes CGU, et à défaut de solution amiable, les tribunaux compétents seront ceux du ressort de la Cour d’appel du lieu où le siège social de Bien Vieillir IDF.
      </p>
      <h3 class="cgu__subtitle">12. Contact</h3>
      <p>
        Pour toute question ou réclamation concernant l'application ou les présentes CGU, vous pouvez nous contacter à l'adresse suivante : <span style="text-decoration: underline; color: #1e48d1">contact@bienvieillir-idf.org</span>.
      </p>
      <p>
        Vous pouvez également contacter notre Délégué à la Protection des Données à l’adresse suivante pour toute question relative à la gestion de vos données personnelles : <span style="text-decoration: underline; color: #1e48d1">dpo@bienvieillir-idf.org</span>.
      </p>
</div>
</template>
<script>
export default {
    name: "Cgu"
}
</script>
<style scoped>
.cgu__title {
    font: normal 20px/17px "ProximaNova", sans-serif;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
}

.cgu__subtitle {
    font: 700 16px/28px "ProximaNova", sans-serif;
}
.cgu  {
    font: normal 14px/28px "ProximaNova", sans-serif;
    margin-top: 2rem;
}
</style>