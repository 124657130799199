<template>
  <div class="bo--prestations row mx-0 justify-content-center">
    <div class="col-sm-12 col-md-11 col-lg-9 table--wrap">
      <div class="d-flex align-items-center">
        <h1 class="bo--agence-title">Liste des prestations</h1>
        <span class="bo--agence-title-notification error" v-if="!synchronized">Non synchronisé</span>
        <span class="bo--agence-title-notification success" v-else>Synchronisé</span>
        <span class="bo--agence-title-count">{{total}} {{total>1 ? 'éléments' : 'élément'}}</span>
      </div>
      <div class="col-md-12">
        <button class="float-right btn btn-primary mt-1 ml-1" @click="createPrestation" v-if="canUserCreatePrestation"> Créer une Prestation</button>
        <button class="float-right btn btn-outline-primary mt-1 ml-1" @click="refresh"><font-awesome-icon class="ic ml-1 mr-1" :icon="['fas', 'sync-alt']"/></button>
      </div>
      <Grid ref="grid"
            id="PrestationList"
            :columns="columns"
            :dataList="prestationList"
            :sortColumn="sortColumn"
            :sortDirection="sortDirection"
            :currentPage="currentPage"
            :loading="loading"
            :total="total"
            :canEditOrDelete="canUserCreatePrestation"
            :canEdit="canUserCreatePrestation"
            :canDelete="canUserDeletePrestation"
            @updateCurrentPage="limitCo => limit = limitCo"
            @dataLoading="dataLoading"
            @editComponent="editPrestation"
            @confirmRemove="confirmRemove"
      />
    </div>
    <b-modal id="archive" hide-footer title="Archive" :centered="true">
        <template v-slot:modal-title>
          Êtes-vous sur de vouloir supprimer cette prestation ?
        </template>
        <b-button class="mt-3" variant="btn col-3 btn-outline-secondary" @click.prevent="$bvModal.hide('archive')">Annuler</b-button>
        <b-button class="mt-2" variant="btn offset-5 col-4 btn-outline-danger" @click.prevent="removePrestation">Supprimer</b-button>
      </b-modal>
  </div>
</template>

<script>
  import Grid from "@/components/Grid/Grid";
  //import {MixinStructureHeader} from "@/components/Util/MixinHeader";
  import {mapState, mapActions, mapGetters} from 'vuex'

  export default {
    name: "PrestationList",
    //mixins: [MixinStructureHeader()],
    components: {
      Grid
    },
    data() {
      return {
        columns: [
         // {name: "Structure", value: 'structureName', column: 'structure.id', sortable: true},
          {name: "Nom", value: 'name', column: 'name', sortable: true},
          {name: "Type", value: 'nameType', column: 'nameType', sortable: true},
          {name: "Horaire", value: 'schedule', column: 'schedule', sortable: true},
          {name: "Récurrence", value: 'recurrency', column: 'recurrency', sortable: false},
          {name: "Durée (Heure)", value: 'time', column: 'time', sortable: true},
          {name: "Prix", value: 'price', column: 'price', sortable: true},
        ],
        sortColumn: 'name',
        sortDirection: 'ASC',
        limit: 10,
        currentPage: 1,
        removeablePrestation: null,
      };
    },
    computed: {
      ...mapState("Prestation", ["loading", "prestations", "total", "error"]),
      ...mapState("User", ["connectedUser"]),
      ...mapGetters("Notification", ["getNotificationByInterface"]),
      //headerColumns(){},headerColumns is set in mixin MixinStructureHeader
      synchronized() {
        return this.getNotificationByInterface.Prestation && this.getNotificationByInterface.Prestation.reduce((value, line) => {
          if (line.type !== "success") {
            value = false;
          }
          return value;
        }, true);
      },
      tdStyle() {
        return `width:110px`;
      },
      canUserCreatePrestation() {
        let storedPermissions = sessionStorage.getItem('permissions');
        let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

        return (permissionsArray.includes('CAN_CREATE_PRESTATION') && permissionsArray.includes('CAN_UPDATE_PRESTATION'));
      },
      canUserUpdatePrestation() {
        let storedPermissions = sessionStorage.getItem('permissions');
        let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

        return (permissionsArray.includes('CAN_UPDATE_PRESTATION'));
      },
      canUserDeletePrestation() {
        let storedPermissions = sessionStorage.getItem('permissions');
        let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

        return permissionsArray.includes('CAN_UPDATE_PRESTATION');
      },
      prestationList() {
        return this.prestations.map(function (item) {
          return {
            ...item,
            recurrency: item.recurrency ? item.recurrency.join(',') : null,
            structureName: item.structure ? item.structure.name : null
          }
        });
      }
    },
    mounted: function () {
      this.limit = window.localStorage.getItem("limit") ? parseInt(window.localStorage.getItem("limit")) : 10;
      if (this.prestations.length == 0) {
        this.dataLoading({limit: this.limit, offset: this.limit * (this.currentPage -1)});
      }
    },
    methods: {
      ...mapActions("Prestation", ["getPrestationList", "deletePrestation"]),
      dataLoading(data) {
        this.getPrestationList(data);
      },
      refresh() {
        this.currentPage = 1;
        this.sortColumn = "name";
        this.sortDirection = "ASC";
        this.getPrestationList({
          limit: this.limit,
          offset: this.limit * (this.currentPage -1),
          sortColumn: this.sortColumn,
          sortDirection: this.sortDirection,
        });

        this.$refs.grid.refreshFilter();
      },
      editPrestation(id) {
        this.$router.push({name: 'prestation-form', params: {id: id}});
      },
      createPrestation() {
        this.$router.push({name: 'prestation-form'});
      },
      confirmRemove(prestation) {
        this.removeablePrestation = prestation;
        this.$bvModal.show('archive');
      },
      async removePrestation() {
        this.$bvModal.hide('archive');
        await this.deletePrestation({id: this.removeablePrestation.id});
        if (this.error === null) {
          this.$notify.success("La prestation a été archivée");
        } else {
          this.$notify.success("Impossible de supprimer la prestation");
        }
        this.removeablePrestation = null;
      }
    }
  };
</script>