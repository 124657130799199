import actions from './actions'
import mutations from "../User/mutations";
import getters from "../User/getters";
import state from "../User/state";

export default {
    namespaced: true,
    actions,
    mutations,
    getters,
    state
};