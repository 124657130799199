<template>
    <FormSelect
        v-model="nationality"
        :label="label"
        :error="error ? 'La nationalité est obligatoire.' : null"
        :disabled="disabled"
        :login-input="false"
        @input="onSelect"
    >
      <template v-slot:first>
        <optgroup label="France">
          <option value="Français">Français</option>
        </optgroup>
        <optgroup label="Autres pays européens">
          <option value="Albanais">Albanais</option>
          <option value="Allemand">Allemand</option>
          <option value="Andorran">Andorran</option>
          <option value="Anglais">Anglais</option>
          <option value="Autrichien">Autrichien</option>
          <option value="Belge">Belge</option>
          <option value="Biélorusse">Biélorusse</option>
          <option value="Bosniaque">Bosniaque</option>
          <option value="Bulgare">Bulgare</option>
          <option value="Chypriote">Chypriote</option>
          <option value="Croate">Croate</option>
          <option value="Danois">Danois</option>
          <option value="Ecossais">Écossais</option>
          <option value="Espagnol">Espagnol</option>
          <option value="Estonien">Estonien</option>
          <option value="Finlandais">Finlandais</option>
          <option value="Gallois">Gallois</option>
          <option value="Grec">Grec</option>
          <option value="Hongrois">Hongrois</option>
          <option value="Irlandais">Irlandais</option>
          <option value="Italien">Italien</option>
          <option value="Letton">Letton</option>
          <option value="Lituanien">Lituanien</option>
          <option value="Luxembourgeois">Luxembourgeois</option>
          <option value="Macédonien">Macédonien</option>
          <option value="Maltais">Maltais</option>
          <option value="Moldave">Moldave</option>
          <option value="Monégasque">Monégasque</option>
          <option value="Néerlandais">Néerlandais</option>
          <option value="Norvégien">Norvégien</option>
          <option value="Polonais">Polonais</option>
          <option value="Portugais">Portugais</option>
          <option value="Roumain">Roumain</option>
          <option value="Russe">Russe</option>
          <option value="Serbe">Serbe</option>
          <option value="Slovaque">Slovaque</option>
          <option value="Slovène">Slovène</option>
          <option value="Suédois">Suédois</option>
          <option value="Suisse">Suisse</option>
          <option value="Tchèque">Tchèque</option>
          <option value="Ukrainien">Ukrainien</option>
        </optgroup>
        <optgroup label="Afrique">
          <option value="Algérien">Algérien</option>
          <option value="Angolais">Angolais</option>
          <option value="Béninois">Béninois</option>
          <option value="Botswanais">Botswanais</option>
          <option value="Burkinabé">Burkinabé</option>
          <option value="Burundais">Burundais</option>
          <option value="Camerounais">Camerounais</option>
          <option value="Cap-Verdien">Cap-Verdien</option>
          <option value="Centrafricain">Centrafricain</option>
          <option value="Comorien">Comorien</option>
          <option value="Congolais">Congolais</option>
          <option value="Djiboutien">Djiboutien</option>
          <option value="Égyptien">Égyptien</option>
          <option value="Érythréen">Érythréen</option>
          <option value="Éthiopien">Éthiopien</option>
          <option value="Gabonais">Gabonais</option>
          <option value="Gambien">Gambien</option>
          <option value="Ghanéen">Ghanéen</option>
          <option value="Guinéen">Guinéen</option>
          <option value="Ivoirien">Ivoirien</option>
          <option value="Kenyan">Kenyan</option>
          <option value="Lesothan">Lesothan</option>
          <option value="Libérien">Libérien</option>
          <option value="Libyen">Libyen</option>
          <option value="Malagasy">Malgache</option>
          <option value="Malawite">Malawite</option>
          <option value="Malien">Malien</option>
          <option value="Marocain">Marocain</option>
          <option value="Mauricien">Mauricien</option>
          <option value="Mauritanien">Mauritanien</option>
          <option value="Nigérian">Nigérian</option>
          <option value="Rwandais">Rwandais</option>
          <option value="Sénégalais">Sénégalais</option>
          <option value="Sud-Africain">Sud-Africain</option>
          <option value="Tchadien">Tchadien</option>
          <option value="Tunisien">Tunisien</option>
        </optgroup>
        <optgroup label="Asie">
          <option value="Afghan">Afghan</option>
          <option value="Arménien">Arménien</option>
          <option value="Azerbaïdjanais">Azerbaïdjanais</option>
          <option value="Bangladais">Bangladais</option>
          <option value="Cambodgien">Cambodgien</option>
          <option value="Chinois">Chinois</option>
          <option value="Coréen">Coréen</option>
          <option value="Indien">Indien</option>
          <option value="Indonésien">Indonésien</option>
          <option value="Iranien">Iranien</option>
          <option value="Irakien">Irakien</option>
          <option value="Israélien">Israélien</option>
          <option value="Japonais">Japonais</option>
          <option value="Jordanie">Jordanien</option>
          <option value="Kazakhstanais">Kazakhstanais</option>
          <option value="Pakistanais">Pakistanais</option>
          <option value="Palestinien">Palestinien</option>
          <option value="Philippin">Philippin</option>
          <option value="Saoudien">Saoudien</option>
          <option value="Singapourien">Singapourien</option>
          <option value="Sri-lankais">Sri-lankais</option>
          <option value="Syrien">Syrien</option>
          <option value="Thaïlandais">Thaïlandais</option>
          <option value="Turc">Turc</option>
          <option value="Vietnamien">Vietnamien</option>
        </optgroup>
        <optgroup label="Amérique">
          <option value="Américain">Américain</option>
          <option value="Argentin">Argentin</option>
          <option value="Brésilien">Brésilien</option>
          <option value="Canadien">Canadien</option>
          <option value="Chilien">Chilien</option>
          <option value="Colombien">Colombien</option>
          <option value="Cubain">Cubain</option>
          <option value="Haïtien">Haïtien</option>
          <option value="Mexicain">Mexicain</option>
          <option value="Péruvien">Péruvien</option>
        </optgroup>
        <optgroup label="Océanie">
          <option value="Australien">Australien</option>
          <option value="Fidjien">Fidjien</option>
          <option value="Néo-Zélandais">Néo-Zélandais</option>
          <option value="Papouasien">Papouasien</option>
        </optgroup>
      </template>
    </FormSelect>
</template>
<script>
import FormSelect from '@/components/Form/Select';

    export default {
        name: "SelectCountry",
        components: {
            FormSelect
        },
        props: {
            value: {
                type: String,
                default: null
            },
            error: {
                type: Boolean,
                default: null
            },
            label: {
                type: String,
                default: null
            },
            disabled: {
                type: Boolean,
                default: null
            }
        },
        data() {
            return {
                nationality: this.value
            }
        },
        watch: {
            value(newValue) {
                this.nationality = newValue;
            }
        },
        methods: {
            onSelect(value) {
                this.$emit('setValue', value);
            }
        }
    }
</script>