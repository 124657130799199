<template v-if="tarification">
  <div class="bo--container">
    <template>
      <div class="container">
        <loader :loader="loading"/>
        <div>
          <bo-card>
            <template slot="title">
              <h1 class="bo-card--title">
                <a href="#" @click.prevent="cancel">&lt; </a> {{isEdit ? 'Modifier la tarification' : "Créer une tarification"}}
              </h1>
            </template>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <form-select v-model="structureId"
                             :disabled="!canManageTarification"
                             :options="structureList"
                             label="La structure *"
                             :error="!$v.structureId.required && $v.structureId.$error? 'la structure est obligatoire.' : null"
                             :login-input="true">
                  <template v-slot:first>
                    <option value="" selected disabled></option>
                  </template>
                </form-select>
              </div>
              <div class="col-sm-12 col-md-6">
                <form-select v-model="prestationId"
                             :disabled="!canManageTarification"
                             :options="prestationlist"
                             label="La prestation *"
                             :error="!$v.prestationId.required && $v.prestationId.$error? 'la prestation est obligatoire.' : null"
                             :login-input="true">
                  <template v-slot:first>
                    <option value="" selected disabled></option>
                  </template>
                </form-select>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <form-input v-model="tarifType"
                            label="Type *"
                            :disabled="!canManageTarification"
                            :error="$v.tarifType.$error ? 'le type est obligatoire' : null"
                            :login-input="true">
                  <templaete v-slot:error>
                    <small v-if="!$v.tarifType.required && $v.tarifType.$error" class="input--error">le type est obligatoire</small>
                  </templaete>
                </form-input>
              </div>
              <div class="col-sm-12 col-md-6">
                  <form-input v-model="price"
                              :disabled="!canManageTarification"
                              label="Prix *"
                              :error="$v.price.$error ? (!$v.price.required ? 'le prix est obligatoire' : 'le prix doit être numérique') : null"
                              :login-input="true">
                    <templaete v-slot:error>
                      <small v-if="!$v.price.required && $v.price.$error" class="input--error">le prix est obligatoire</small>
                      <small v-if="!$v.price.numeric && $v.price.$error" class="input--error">le prix est incorrect</small>
                    </templaete>
                  </form-input>
              </div>
            </div>
            <template slot="footer">
              <button v-if="canManageTarification" class="btn btn-primary" @click.prevent="submit" :disabled="saveLoading"><font-awesome-icon v-show="saveLoading" :icon="['fas', 'spinner']" spin/> Enregistrer</button>
              <button class="btn btn-outline-primary" @click.prevent="cancel">Annuler</button>
            </template>
          </bo-card>
        </div>
      </div>
    </template>
  </div>
</template>
<script>

import {mapState, mapActions, mapMutations} from "vuex";
import { required, decimal } from 'vuelidate/lib/validators'

export default {
  name: "BOTarificationForm",
  components: {},
  data() {
    return {
      bo: {
        active: true
      },
      saveLoading: false,
      tarifTypeOptions: [
        {text: 'Ordinaire', value: 'Ordinaire'},
        {text: 'Essentiel', value: 'Essentiel'}
      ]
    };
  },
  validations() {
    let dataReturn = {
      structureId: {
        required
      },
      prestationId: {
        required
      },
      tarifType: {
        required
      },
      price: {
        required,
        decimal
      },
    };
    return dataReturn;
  },
  computed: {
    ...mapState('Tarification', ['tarification', 'error', 'loading']),
    ...mapState('Structure', ['structures']),
    ...mapState('Prestation', ['prestations']),
    ...mapState('User', ['connectedUser']),
    structureId:{
      get(){
        return this.tarification.structureId;
      },
      set(value){
        this.setTarificationProperty({
          name: "structureId",
          value: value
        });
      }
    },
    canManageTarification: {
      get() {
        let storedPermissions = sessionStorage.getItem('permissions');
        let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

        return permissionsArray.includes('CAN_UPDATE_TARIFICATION');
      }
    },
    prestationId:{
      get(){
        return this.tarification.prestationId;
      },
      set(value){
        this.setTarificationProperty({
          name: "prestationId",
          value: value
        });
      }
    },
    tarifType:{
      get(){
        return this.tarification.tarifType;
      },
      set(value){
        this.setTarificationProperty({
          name: "tarifType",
          value: value
        });
      }
    },
    price:{
      get(){
        return this.tarification.price;
      },
      set(value){
        this.setTarificationProperty({
          name: "price",
          value: value
        });
      }
    },
    isEdit() {
      return (this.$route.params.id !== undefined && this.$route.params.id !== null)
    },
    structureList() {
      let structureList = [];
      this.structures.forEach(item => structureList.push({value: item.id, text: item.name}));
      return structureList;
    },
    prestationlist() {
      let prestationlist = [];
      this.prestations.forEach(item => prestationlist.push({value: item.id, text: item.name}));
      return prestationlist;
    }
  },
  mounted: async function() {
    this.setInitTarification();
    if (this.isEdit) {
      this.getTarification({ id: this.$route.params.id })
      .catch((err) => {
        if (err) {
          this.$notify.error("Impossible d'accéder à cette tarification")
          this.$router.push({name: 'tarification-list'});
        }
      })
    }
    this.getStructureList({limit: 1000});
    this.getPrestationList({limit: 1000});
  },
  methods: {
    ...mapMutations('Tarification',['setTarificationProperty','setInitTarification']),
    ...mapActions('Tarification', ['postTarification', 'putTarification', 'getTarification']),
    ...mapActions('Structure', ['getStructureList']),
    ...mapActions('Prestation', ['getPrestationList']),
    async submit() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return; 
      }
      this.$confirm('Êtes-vous sur de vouloir réaliser une modification ? Les données modifiées dans DANEAD doivent être modifiées dans le logiciel métier !').then(() => {
        this.afterConfirm();
      }).catch(() => {});
    },
    afterConfirm(){
      this.saveLoading = true;
      if (this.isEdit) {
        this.putTarification().then((data) => {
          this.backToList();
          return data;
        }).catch((err) => {
          this.errorSave();
          throw err;
        });
      } else {
        this.postTarification().then((data) => {
          this.backToList();
          return data;
        }).catch((err) => {
          this.errorSave();
          throw err;
        });
      }
    },
    backToList(){
      this.setInitTarification();
      this.saveLoading = false;
      this.$notify.success(this.isEdit ? "La tarification a été modifiée" : "La tarification a été créée")
      this.$router.push({name: 'tarification-list'});
      return;
    },
    errorSave(){
      this.saveLoading = false;
      this.$notify.error("Erreur de sauvegarde, vérifiez les informations saisies")
    },
    cancel() {
      this.setInitTarification();
      return this.$router.push({name:'tarification-list'})
    }
  }
};
</script>