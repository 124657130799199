<template>
  <div class="bo--agence row mx-0 justify-content-center">
    <div class="col-sm-12 col-md-11 col-lg-9 table--wrap">
      <div class="d-flex align-items-center">
        <h1 class="bo--agence-title">Liste des évaluations</h1>
        <span class="bo--agence-title-notification info">Pas de synchronisation</span>
        <span class="bo--agence-title-count">{{total}} élement{{total > 1 ? 's' : ''}}</span>
      </div>
      <div class="col-md-12">
        <button class="float-right btn btn-outline-primary mt-1 ml-1" @click="refresh"><font-awesome-icon class="ic ml-1 mr-1" :icon="['fas', 'sync-alt']"/></button>
      </div>
      <Grid ref="grid"
            id="EvaluationList"
            :columns="headerColumns"
            :dataList="evaluationList"
            :sortColumn="sortColumn"
            :sortDirection="sortDirection"
            :currentPage="currentPage"
            :loading="loading"
            :total="total"
            :canEditOrDelete="canUpdateEvaluation"
            :canEdit="canUpdateEvaluation"
            :canDelete="canDeleteEvaluation"
            @updateCurrentPage="limitCo => limit = limitCo"
            @dataLoading="dataLoading"
            @editComponent="editEvaluation"
            @confirmRemove="confirmRemove"
            @changeHeaderAutocomplete="filterManager"
      />
    </div>
    <b-modal id="archive" hide-footer title="Archive" :centered="true">
        <template v-slot:modal-title>
          Êtes-vous sur de vouloir archiver cette evaluation?
        </template>
        <b-button class="mt-3" variant="btn col-3 btn-outline-secondary" @click.prevent="$bvModal.hide('archive')">Annuler</b-button>
        <b-button class="mt-2" variant="btn offset-6 col-3 btn-outline-danger" @click.prevent="removeEvaluation">Archiver</b-button>
      </b-modal>
  </div>
</template>

<script>
  import Grid from "@/components/Grid/Grid";
  import {MixinStructureHeader} from "@/components/Util/MixinHeader";
  import {mapState, mapActions, mapGetters} from 'vuex';
  import moment from 'moment';

  export default {
    name: "EvaluationList",
    mixins: [MixinStructureHeader()],
    components: {
      Grid
    },
    data() {
      return {
        columns: [
          {name: "Structure", value: 'structure_name', column: 'structure.id', sortable: true},
          {name: "Evaluateur/trice", value: 'evaluator', column: 'evaluate_by.id', sortable: true},
          {name: "Client", value: 'client_name', column: 'client.id', sortable: true},
          {name: "% AGGIR", value: 'aggirPercentage', column: 'aggirPercentage', sortable: true},
          {name: "Date de l'évaluation", value: 'evaluation_date', column: 'evaluation_date', sortable: true},
          {name: "Date de début", value: 'starting_date', column: 'starting_date', sortable: true},
          {name: "Date de fin", value: 'ending_date', column: 'ending_date', sortable: true}
        ],
        sortColumn: 'structure.name',
        sortDirection: 'ASC',
        limit: 10,
        currentPage: 1,
        removeableEvaluation: null,
        userQuery: ''
      };
    },
    computed: {
      ...mapState("Evaluation", ["loading", "evaluations", "total", "error"]),
      ...mapState("User", ["users"]),
      ...mapGetters('User', ['isUserRoleOperationManager']),
      //headerColumns(){},headerColumns is set in mixin MixinStructureHeader
      tdStyle() {
        //return `width:${(100 / this.columns.length).toFixed(1)}%`;
        return `width:110px`;
      },
      canUpdateEvaluation: {
        get() {
          let storedPermissions = sessionStorage.getItem('permissions');
          let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

          return permissionsArray.includes('CAN_UPDATE_EVALUATION');
        }
      },
      canDeleteEvaluation: {
        get() {
          let storedPermissions = sessionStorage.getItem('permissions');
          let permissionsArray = storedPermissions ? storedPermissions.split(',') : [];

          return permissionsArray.includes('CAN_DELETE_EVALUATION');
        }
      },
      evaluationList() {
        return this.evaluations.map(function (item) {
          return {
            ...item,
            structure_name: item.structure ? item.structure.name :  null,
            evaluator: item.evaluateBy ? ` ${item.evaluateBy.firstName}  ${item.evaluateBy.lastName}` :  null,
            client_name: item.client ? ` ${item.client.firstname}  ${item.client.lastname}` :  null,
            evaluation_date: item.evaluationDate ? moment(item.evaluationDate).format('DD/MM/YYYY') : null,
            starting_date: item.startingDate ? moment(item.startingDate).format('DD/MM/YYYY') : null,
            ending_date: item.endingDate ? moment(item.endingDate).format('DD/MM/YYYY') : null,
            createdUser: item.createdBy ? item.createdBy.firstName + ' ' + item.createdBy.lastName : null,
            updatedUser: item.updatedBy ? item.updatedBy.firstName + ' ' + item.updatedBy.lastName : null,
            managerUser: item.manager ? item.manager.firstName + ' ' + item.manager.lastName : null,
            createdDate: item.createdAt ? moment(item.createdAt).format('DD/MM/YYYY') : null,
            updatedDate: item.updatedAt ? moment(item.updatedAt).format('DD/MM/YYYY') : null
          }
        });
      }
    },
    mounted: function () {
      this.limit = window.localStorage.getItem("limit") ? parseInt(window.localStorage.getItem("limit")) : 10;
      if (this.evaluations.length == 0) {
        this.dataLoading({limit: this.limit, offset: this.limit * (this.currentPage -1)});
      }
    },
    methods: {
      ...mapActions("Evaluation", ["getEvaluationList", "deleteEvaluation"]),
      ...mapActions("User", ["getUserList"]),
      filterManager(q) {
          this.userQuery = q;
      },
      dataLoading(data) {
        this.getEvaluationList(data);
      },
      refresh() {
        this.currentPage = 1;
        this.sortColumn = "structure.name";
        this.sortDirection = "ASC";
        this.getEvaluationList({
          limit: this.limit,
          offset: this.limit * (this.currentPage -1)
        });

        this.$refs.grid.refreshFilter();
      },
      editEvaluation(id) {
        this.$router.push({name: 'evaluation-form', params: {id: id}});
      },
      createEvaluation() {
        this.$router.push({name: 'evaluation-form'});
      },
      confirmRemove(evaluation) {
        this.removeableEvaluation = evaluation;
        this.$bvModal.show('archive');
      },
      async removeEvaluation() {
        this.$bvModal.hide('archive');
        await this.deleteEvaluation({id: this.removeableEvaluation.id});
        if (this.error === null) {
          this.$notify.success("L'évaluation a été archivée")
        } else {
          this.$notify.success("Impossible d'archiver l'évaluation")
        }
        this.removeableEvaluation = null;
      }
    }
  };
</script>